import { Stack, Typography, useTheme } from "@mui/material";
import { SmallModal } from "../../../components/SmallModal";
import { RegistryFieldType } from "../../../models/types";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import { useState } from "react";
import { CustomInputValidation } from "../../CustomInputValidation";
import { LoadingButton } from "@mui/lab";

type LinkExternalRequirementModalProps = {
  open: boolean;
  onClose: () => void;
  requirement: any;
  requirementType: RegistryFieldType | null;
  onLink: (requirement: string, requirementType: RegistryFieldType | null) => void;
};

export const LinkExternalRequirementModal = (props: LinkExternalRequirementModalProps) => {
  const {
    open,
    onClose,
    requirement: _requirement,
    requirementType: _requirementType,
    onLink,
  } = props;
  const theme = useTheme();
  const innerTextColor = theme.palette.custom.secondaryTypography;

  const [requirementType, setRequirementType] = useState<RegistryFieldType | null>(
    _requirementType
  );
  const [requirement, setRequirement] = useState<any>(_requirement);

  const requirementTypeOptions: RegistryFieldType[] = ["Policy", "Hyperlink", "Jira Ticket"];

  return (
    <SmallModal open={open} onClose={onClose} title="Link External Requirement">
      <Stack gap="10px">
        <Typography variant="h3" color={innerTextColor}>
          Requirement Type
        </Typography>
        <SearchableDropdown<RegistryFieldType>
          value={requirementType}
          onChange={(option) => {
            setRequirementType(option);
          }}
          getOptions={(search: string) =>
            requirementTypeOptions.filter((option) =>
              option.toLowerCase().includes(search.toLowerCase())
            )
          }
          getOptionLabel={(option) => option}
          isOptionEqualToValue={(a, b) => a === b}
        />
        {requirementType && (
          <>
            <Typography variant="h3" color={innerTextColor}>
              Requirement
            </Typography>
            <CustomInputValidation
              value={requirement}
              onChange={setRequirement}
              required
              valueError={false}
              errorMessage="Requirement is required"
              field_type={requirementType === "Policy" ? "Asset" : requirementType}
            />
          </>
        )}
        <LoadingButton
          variant="contained"
          onClick={() => onLink(requirement, requirementType)}
          disabled={!requirement || !requirementType}
        >
          Link
        </LoadingButton>
      </Stack>
    </SmallModal>
  );
};
