import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { INavbarIconVariant, NavbarIcon } from "../../../atoms/navbar/Icon";
import { navigateToAsset } from "../../../components/AssetNavigator";
import CKEditorField from "../../../components/custom-fields/CKEditorField";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import { SmallModal } from "../../../components/SmallModal";
import { openSnackbar } from "../../../context/SnackbarContext";
import { searchHuggingFaceModels } from "../../../hooks/useHuggingFace";
import { useInputState } from "../../../hooks/useInputTextState";
import { searchMLFlowModels } from "../../../hooks/useMLFlow";
import { usePluginInfo } from "../../../hooks/usePlugins";
import {
  Asset,
  AssetSourceOptions,
  DatabricksUnityCatalogModel,
  HuggingFaceModel,
} from "../../../models/types";
import { getProcessModel } from "../../../services/DatabricksService";
import { addAsset } from "../../../services/DataService";
import { useAssetTypesGet } from "../../../state/QueryStore";
import { CustomAxiosError } from "../../../utilities/ErrorResponseHelper";
import { CustomInputValidation } from "../../CustomInputValidation";
import { CreateDatabricksModel } from "./CreateDatabricksModel";
import { fetchAssets, searchAssetsWithParams, useAsset } from "../../../hooks/useAssets";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { ConnectModelsUseCaseModal } from "../ConnectModelsUseCase";

type CreateAssetModelModalProps = {
  /** Should the modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  /** Is Databricks available */
  isDatabricks: boolean;
  onCreate?: (modelId: string) => void;
  showConfirmationModal?: boolean;
  showDropdown?: boolean;
};

export const CreateAssetModelModal = (props: CreateAssetModelModalProps) => {
  const {
    open,
    onClose,
    onCreate: _onCreate,
    isDatabricks,
    showConfirmationModal = false,
    showDropdown = false,
  } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const textColor = theme.palette.custom.secondaryTypography;
  const { data: assetTypes } = useAssetTypesGet({ fairo_data: true });
  const [selectedModel, setSelectedModel] = useState<string | null>(null);
  const { data } = useAsset(selectedModel || "");
  const [createNew, setCreateNew] = useState<boolean>(false);

  const sourceOptions: {
    name: string;
    icon: INavbarIconVariant;
  }[] = [
    {
      name: "Fairo",
      icon: "fairo-logo",
    },
    ...(isDatabricks
      ? [{ name: "Databricks", icon: "databricks" as INavbarIconVariant }]
      : [{ name: "MLFlow", icon: "mlflow" as INavbarIconVariant }]),
    {
      name: "Hugging Face",
      icon: "hugging-face",
    },
  ];

  const [source, setSource] = useState<string | null>(null);
  const [mlflowModel, setMLFlowModel] = useState<{ name: string } | null>(null);
  const [huggingFaceModel, setHuggingFaceModel] = useState<HuggingFaceModel | null>(null);
  const [name, setName, nameError, setNameError] = useInputState(
    mlflowModel ? mlflowModel.name : ""
  );
  const [description, setDescription] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [modelId, setModelId] = useState<string>("");
  const { data: integration } = usePluginInfo("mlflow");
  const { data: huggingFaceIntegration } = usePluginInfo("hugging_face");

  const isDatabricksIntegration = integration?.tracking_server === "Databricks";
  const isHuggingFaceAvailable = !!huggingFaceIntegration?.hugging_face_token;

  const onCreateConfirmation = async () => {
    if (showDropdown && selectedModel && _onCreate) {
      _onCreate(selectedModel);
      return;
    }

    if (source === "Fairo" && !name) {
      setNameError(true);
      return;
    }

    const newName =
      source === "MLFlow" || source === "Databricks"
        ? mlflowModel?.name ?? ""
        : source === "Hugging Face"
        ? huggingFaceModel?.id ?? ""
        : name;
    const newSource =
      isDatabricksIntegration && source === "MLFlow"
        ? "DATABRICKS"
        : (source?.toUpperCase() as AssetSourceOptions);
    try {
      const models = await fetchAssets({
        name: [newName],
        "asset_type_name[]": ["Model"],
        "source[]": [newSource],
      });
      if (models.results.length > 0) {
        setOpenConfirmation(true);
        setModelId(models.results[0].id);
      } else {
        onCreate();
      }
    } catch {
    } finally {
      setCreateNew(false);
    }
  };

  const onCreate = async () => {
    if (source === "Fairo" && !name) {
      setNameError(true);
      return;
    }

    const newDescription = description?.trim() ?? "";

    const newName =
      source === "MLFlow" || source === "Databricks"
        ? mlflowModel?.name ?? ""
        : source === "Hugging Face"
        ? huggingFaceModel?.id ?? ""
        : name;

    try {
      setLoading(true);
      const typeId = assetTypes?.find((type) => type.name === "Model")?.id;
      if (!typeId) {
        openSnackbar("Failed to load model Asset Type", "error");
        return;
      }
      const { data: createdAsset } = await addAsset({
        name: newName,
        description: isDatabricksIntegration
          ? (mlflowModel as DatabricksUnityCatalogModel)?.comment
          : newDescription,
        type: typeId,
        source:
          isDatabricksIntegration && source === "MLFlow"
            ? "DATABRICKS"
            : (source?.toUpperCase() as AssetSourceOptions),
        metadata: {
          full_model_name: isDatabricksIntegration
            ? (mlflowModel as DatabricksUnityCatalogModel)?.full_name
            : mlflowModel?.name,
        },
      });
      if (isDatabricksIntegration) {
        await getProcessModel(createdAsset.id);
      }
      if (_onCreate) {
        _onCreate(createdAsset.id);
      } else {
        navigateToAsset({
          navigate,
          asset: createdAsset,
          assetId: createdAsset.id,
          assetNumber: createdAsset.asset_num,
        });
      }
      onClose();
    } catch (error) {
      if (error instanceof CustomAxiosError) {
        openSnackbar("Failed to create Model Asset", "error");
      }
    } finally {
      setCreateNew(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (source === "MLFlow" && mlflowModel) {
      if (isDatabricksIntegration) {
        setDescription((mlflowModel as DatabricksUnityCatalogModel)?.comment ?? "");
      } else {
        setDescription("");
      }
    }
  }, [source, mlflowModel]);

  return (
    <>
      <ConfirmationModal
        open={openConfirmation && showConfirmationModal}
        title="Model Exists"
        description="The model exists. Would you like to create a new one or connect the existing one?"
        onAccept={onCreate}
        onClose={() => setOpenConfirmation(false)}
        onReject={() => {
          setOpenConfirmation(false);
          onClose();
          if (_onCreate) {
            _onCreate(modelId);
          }
        }}
        acceptText="Create New Model"
        rejectText="Connect Existing Model"
      />
      <ConnectModelsUseCaseModal
        open={createNew}
        onClose={() => setCreateNew(false)}
        name={name}
        setName={setName}
        nameError={nameError}
        description={description}
        setDescription={setDescription}
        onCreate={showConfirmationModal ? () => onCreateConfirmation() : () => onCreate()}
      />
      <SmallModal open={open} onClose={onClose} title="Create Model">
        <Stack gap="10px">
          <Typography variant="h3" color={textColor}>
            Source
          </Typography>
          <SearchableDropdown<string>
            value={source}
            onChange={(option) => setSource(option)}
            getOptions={(search: string) =>
              sourceOptions
                .filter((option) => option.name.toLowerCase().includes(search.toLowerCase()))
                .map((o) => o.name)
            }
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(a, b) => a === b}
            renderOption={(props, option) => {
              return (
                <Box
                  component="li"
                  {...props}
                  sx={{ cursor: "pointer" }}
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  gap="5px"
                >
                  <Box
                    display="flex"
                    flex={1}
                    flexDirection="row"
                    gap="5px"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" flexDirection="row" gap="5px" alignItems="center" flex={1}>
                      <NavbarIcon
                        variant={sourceOptions.find((o) => o.name === option)?.icon ?? "fairo-logo"}
                        sx={{
                          width: "20px",
                          height: "20px",
                          minHeight: "20px",
                          minWidth: "20px",
                          padding: option === "Fairo" ? "2.5px" : "",
                        }}
                        activeMLFlow={true}
                      />
                      <Typography variant="h4">{option}</Typography>
                    </Box>
                    {option === "Hugging Face" && !isHuggingFaceAvailable && (
                      <Button
                        sx={{ height: "30px" }}
                        onClick={() => navigate("/organization/integrations")}
                      >
                        Set Up Now
                      </Button>
                    )}
                  </Box>
                </Box>
              );
            }}
          />
          {source === "MLFlow" && !isDatabricksIntegration && (
            <>
              <Typography variant="h3" color={textColor}>
                MLFLow Model
              </Typography>
              <SearchableDropdown<{ name: string }>
                value={mlflowModel}
                onChange={(option) => setMLFlowModel(option)}
                getOptions={searchMLFlowModels}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(a, b) => a.name === b.name}
              />
            </>
          )}
          {source === "Databricks" && isDatabricksIntegration && (
            <CreateDatabricksModel model={mlflowModel} setModel={setMLFlowModel} />
          )}
          {source === "Fairo" && (
            <>
              {showDropdown ? (
                <>
                  <Typography variant="h3" color={textColor}>
                    Model
                  </Typography>
                  <SearchableDropdown<Asset>
                    label="Asset"
                    required={true}
                    error={false}
                    value={data || null}
                    onChange={(newAsset) =>
                      newAsset != null ? setSelectedModel(newAsset.id) : setSelectedModel(null)
                    }
                    getOptions={searchAssetsWithParams({
                      "asset_type_name[]": ["Model"],
                      "source[]": ["FAIRO"],
                    })}
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    getSummaryDescription={(asset) => [asset.name, asset.description ?? ""]}
                    actionButton={["Create Model", () => setCreateNew && setCreateNew(true)]}
                  />
                </>
              ) : (
                <>
                  <Typography variant="h3" color={textColor}>
                    Name
                  </Typography>
                  <CustomInputValidation
                    value={name}
                    onChange={setName}
                    valueError={nameError}
                    errorMessage="Name is required"
                    field_type="String"
                  />
                  <Typography variant="h3" color={textColor}>
                    Description
                  </Typography>
                  <CKEditorField value={description} onChange={setDescription} />
                </>
              )}
            </>
          )}
          {source === "Hugging Face" && (
            <>
              <SearchableDropdown
                value={huggingFaceModel}
                onChange={(option) => setHuggingFaceModel(option)}
                getOptions={searchHuggingFaceModels}
                getOptionLabel={(option) => option.id}
                isOptionEqualToValue={(a, b) => a._id === b._id}
              />
            </>
          )}
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={showConfirmationModal ? () => onCreateConfirmation() : () => onCreate()}
            disabled={
              showDropdown
                ? selectedModel === ""
                : !source ||
                  (source === "Fairo" && !name) ||
                  (source === "MLFLow" && !mlflowModel) ||
                  (source === "Hugging Face" && !huggingFaceModel)
            }
          >
            Create
          </LoadingButton>
        </Stack>
      </SmallModal>
    </>
  );
};
