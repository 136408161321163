import { Box, Typography, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { StringCustomField } from "../../components/CustomFieldForm";
import { useAuth } from "../../hooks/useAuth";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

type OrganizationSetupProps = {
  value: string;
  onChange: (newVal: any) => void;
  required?: boolean;
  metadata?: any;
};

export const OrganizationSetup = forwardRef((props: OrganizationSetupProps, ref) => {
  const { value, onChange, required, metadata } = props;
  const { organization } = useAuth();
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const [stage, setStage] = useState<string>("");
  const [industry, setIndustry] = useState<string>("");

  const [validationErrors, setValidationErrors] = useState({
    name: false,
    description: false,
    stage: false,
    industry: false,
  });

  const handleKeyChange = (newValue: string, key: string) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, [key]: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const getValue = (key: string) => {
    const valueJson = JSON.parse(value || "{}");
    return valueJson[key];
  };

  const stages: string[] = metadata?.stages || [
    "Startup",
    "Growing Business",
    "Mature Company",
    "Enterprise",
    "Non-profit",
    "Other",
  ];

  const industries: string[] = metadata?.industry || [];

  useImperativeHandle(ref, () => ({
    validate: () => {
      const errors = {
        name: !getValue("name") || getValue("name") === "",
        description:
          !getValue("description") ||
          getValue("description") === "" ||
          getValue("description").length > 300,
        stage: !getValue("stage") || getValue("stage") === "",
        industry: !getValue("industry") || getValue("industry") === "",
      };

      setValidationErrors(errors);

      // Return true if all fields are valid (no errors)
      return !Object.values(errors).some((hasError) => hasError);
    },
  }));

  useEffect(() => {
    if (organization && value) {
      const valueJson = JSON.parse(value || "{}");
      const name = valueJson["name"];
      const description = valueJson["description"];
      const stage = valueJson["stage"];
      const industry = valueJson["industry"];
      if (stage && stage !== "" && !stages.includes(stage)) {
        setStage("Other");
      } else {
        setStage(stage ? stage : organization.stage);
      }
      if (industry && industry !== "" && !industries.includes(industry)) {
        setIndustry("Other");
      } else {
        setIndustry(industry ? industry : organization.industry);
      }
      const newValueJson = {
        name: name ? name : organization?.name,
        description: description ? description : organization.description,
        stage: stage ? stage : organization.stage,
        industry: industry ? industry : organization.industry,
      };
      onChange(JSON.stringify(newValueJson));
      setLoading(false);
    }
  }, []);

  return loading ? (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" gap="10px">
      <RectangularLoader width="100%" height="40px" />
    </Box>
  ) : (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" gap="10px">
      <Typography variant="h3">What is the name of your organization?</Typography>
      <StringCustomField
        required={required}
        label="Organization Name"
        value={getValue("name")}
        onChange={(e) => handleKeyChange(e, "name")}
        error={validationErrors.name}
        errorMessage={"Please enter your organization name"}
      />
      <Typography variant="h3">Organization Description</Typography>
      <Typography variant="body2">Describe your organization in a small paragraph</Typography>
      <StringCustomField
        required={required}
        label="Organization Description"
        value={getValue("description")}
        onChange={(e) => handleKeyChange(e, "description")}
        error={validationErrors.description}
        errorMessage={
          "Please enter your organization description and the length should be less than 300 characters"
        }
        minRows={3}
      />
      <Typography variant="body2" color={theme.palette.custom.grayTypography}>
        {(getValue("description") ?? "").length} of 300 characters
      </Typography>
      <Typography variant="h3">
        Which state best describes your organization's current development or growth?
      </Typography>
      <Box display="flex" gap="16px" flexWrap="wrap">
        {stages.map((stageOption) => {
          return (
            <Box
              padding="8.5px 10px"
              display="flex"
              gap="10px"
              border={`1px solid ${
                stage === stageOption
                  ? theme.palette.custom.blue[400]
                  : theme.palette.custom.grey[600]
              }`}
              borderRadius="3px"
              bgcolor={stage === stageOption ? theme.palette.custom.blue[100] : "none"}
              onClick={() => {
                setStage(stageOption);
                if (stageOption === "Other") {
                  handleKeyChange("", "stage");
                } else {
                  handleKeyChange(stageOption, "stage");
                }
              }}
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Typography variant="body1">{stageOption}</Typography>
            </Box>
          );
        })}
      </Box>
      {validationErrors.stage && stage !== "Other" && (
        <Typography variant="small" color={theme.palette.custom.redTypography} marginLeft="14px">
          Please select stage of your organization
        </Typography>
      )}
      {stage === "Other" && (
        <>
          <Typography variant="body2">If you didn't find the option enter it manually</Typography>
          <StringCustomField
            required={required}
            label="Stage"
            value={getValue("stage")}
            onChange={(e) => handleKeyChange(e, "stage")}
            error={validationErrors.stage}
            errorMessage="Please enter stage"
          />
        </>
      )}
      <Typography variant="h3">Which industry best describes your company?</Typography>
      <Box display="flex" gap="16px" flexWrap="wrap">
        {industries.map((industryOption) => {
          return (
            <Box
              padding="8.5px 10px"
              display="flex"
              gap="10px"
              border={`1px solid ${
                industry === industryOption
                  ? theme.palette.custom.blue[400]
                  : theme.palette.custom.grey[600]
              }`}
              borderRadius="3px"
              bgcolor={industry === industryOption ? theme.palette.custom.blue[100] : "none"}
              onClick={() => {
                setIndustry(industryOption);
                if (industryOption === "Other") {
                  handleKeyChange("", "industry");
                } else {
                  handleKeyChange(industryOption, "industry");
                }
              }}
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Typography variant="body1">{industryOption}</Typography>
            </Box>
          );
        })}
      </Box>
      {validationErrors.industry && industry !== "Other" && (
        <Typography variant="small" color={theme.palette.custom.redTypography} marginLeft="14px">
          Please select industry of your organization
        </Typography>
      )}
      {industry === "Other" && (
        <>
          <Typography variant="body2">If you didn't find the option enter it manually</Typography>
          <StringCustomField
            required={required}
            label="Industry"
            value={getValue("industry")}
            onChange={(e) => handleKeyChange(e, "industry")}
            error={validationErrors.industry}
            errorMessage="Please enter Industry"
          />
        </>
      )}
    </Box>
  );
});
