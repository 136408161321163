import { LoadingButton } from "@mui/lab";
import { Box, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { StringCustomField } from "../../components/CustomFieldForm";

type CoWorkersAndRoleProps = {
  value: string;
  onChange: (newVal: any) => void;
  required?: boolean;
};

export const CoWorkersAndRole = (props: CoWorkersAndRoleProps) => {
  const { value, onChange, required } = props;
  const theme = useTheme();
  const valueJson = JSON.parse(value || "[]");

  const addCoWorker = (id: number, email: string, role: string) => {
    const valueJson = JSON.parse(value || "[]");
    const existingIndex = valueJson.findIndex((coworker: any) => coworker.id === id);
    if (existingIndex !== -1) {
      valueJson[existingIndex].email = email;
      valueJson[existingIndex].role = role;
    } else {
      valueJson.push({ id, email, role });
    }
    onChange(JSON.stringify(valueJson));
  };

  useEffect(() => {
    if (value) {
      const valueJson = JSON.parse(value || "[]");
      if (valueJson.length === 0) {
        addCoWorker(0, "", "");
      }
    }
  }, [value]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" gap="10px">
      <Typography
        variant="button"
        color={theme.palette.custom.hyperlink}
        sx={{
          textDecoration: "underline",
          ":hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => addCoWorker(JSON.parse(value || "[]").length, "", "")}
      >
        Add Co-Worker
      </Typography>
      {valueJson.map((value: any, index: number) => {
        return (
          <Box width="100%" display="flex" flexDirection="column" gap="8px" alignItems="center">
            <Box width="100%" display="flex" gap="8px" alignItems="center">
              <Box width="60%">
                <StringCustomField
                  required={required}
                  label="Email"
                  value={value.email}
                  onChange={(e) => addCoWorker(index, e, value.role)}
                  error={false}
                  errorMessage={"Please enter your email"}
                />
              </Box>
              <Box width="40%">
                <StringCustomField
                  required={required}
                  label="Role"
                  value={value.role}
                  onChange={(e) => addCoWorker(index, value.email, e)}
                  error={false}
                  errorMessage={"Please enter your role"}
                />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
