import { useQuery } from "react-query";
import { VendorProcurementRequirementFetchOptions } from "../models/types";
import {
  getVendorProcurementRequirement,
  getVendorProcurementRequirements,
} from "../services/VendorProcurementRequirementService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useVendorProcurementRequirements = (
  params: VendorProcurementRequirementFetchOptions
) => {
  return useQuery([QueryKey.VendorProcurementRequirement, params], async () => {
    try {
      const { data } = await getVendorProcurementRequirements(params);
      return data;
    } catch (error) {
      return { count: 0, next: null, previous: null, results: [] };
    }
  });
};

export const fetchVendorProcurementRequirements = (
  params: VendorProcurementRequirementFetchOptions
) => {
  return queryClient.fetchQuery([QueryKey.VendorProcurementRequirement, params], async () => {
    const { data } = await getVendorProcurementRequirements(params);
    return data;
  });
};

export const useVendorProcurementRequirement = (id: string) => {
  return useQuery([QueryKey.VendorProcurementRequirement, id], async () => {
    if (!id) {
      return null;
    }
    const { data } = await getVendorProcurementRequirement(id);
    return data;
  });
};

export const searchVendorProcurementRequirements = async (searchText: string) => {
  const vendorProcurementRequirements = await fetchVendorProcurementRequirements({
    search: searchText,
  });
  return vendorProcurementRequirements.results;
};
