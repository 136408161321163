import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { ReactComponent as DeleteIcon } from "../assets/general/delete.svg";
import { ReactComponent as EditDarkIcon } from "../assets/general/edit-icon-dark.svg";
import { ReactComponent as EditLightIcon } from "../assets/general/edit-icon-light.svg";
import SecondaryBox from "../atoms/box/SecondaryBox";
import { NavbarIcon } from "../atoms/navbar/Icon";
import config from "../config/config";
import { openSnackbar } from "../context/SnackbarContext";
import { useAuth } from "../hooks/useAuth";
import {
  fetchCustomField,
  useCustomFieldValue,
  useCustomFieldValues,
} from "../hooks/useCustomFields";
import { useInputState } from "../hooks/useInputTextState";
import { searchRatingLevelOptions, useRatingLevelOption } from "../hooks/useRatingLevelOptions";
import { searchTags } from "../hooks/useTags";
import {
  Asset,
  AssetFetchOptions,
  CustomField,
  CustomFieldType,
  CustomFieldValue,
  FieldHandlerProps,
  MLFlowModel,
  RatingLevelOption,
  User,
  UserFetchOptions,
} from "../models/types";
import CustomCalendar from "../molecules/CustomCalendar";
import { JSONView } from "../molecules/JSONView";
import { ShowMore } from "../molecules/ShowMore";
import {
  TagListCustomField,
  TagListReadOnlyCustomField,
} from "../molecules/custom-fields/TagListCustomField";
import { CreateRatingLevelModal } from "../organisms/modals/compliances/CreateRatingLevelOptionModal";
import { CustomFieldLoader } from "../organisms/skeleton-loaders/CustomFieldLoader";
import { addCustomField } from "../services/CustomFieldService";
import { deleteRatingLevelOption } from "../services/RatingLevelOptionService";
import { ListResponse, getAPIErrorMessage } from "../utilities/ApiResponseHelper";
import { CustomAxiosError } from "../utilities/ErrorResponseHelper";
import {
  breakDescriptionIn250Chars,
  dateDisplay,
  defaultDateDisplay,
  isJSON,
  isValidUUID,
} from "../utilities/UIHelper";
import { Awaitable } from "../utilities/common";
import { ConfirmationModal } from "./ConfirmationModal";
import { SearchableDropdown } from "./SearchableDropdown";
import { SmallModal } from "./SmallModal";
import UserTag from "./UserTag";
import CKEditorField from "./custom-fields/CKEditorField";
import { UpdateJSONModal } from "../organisms/modals/UpdateJSONModal";
import { ColorSchemeCustomField } from "../molecules/custom-fields/ColorSchemeCustomField";
import { StyleRules } from "./StyleRules";
import { CustomInputValidation } from "../organisms/CustomInputValidation";
import { MetricResultListReadOnlyCustomField } from "../molecules/custom-fields/MetricResultCustomField";
import { JSONTableView } from "../molecules/JSONTableView";

type CustomFieldFormProps = {
  /** List of custom fields */
  customFieldValues: CustomFieldValue[];
  /** Display loading effect while fetching data */
  isFetching?: boolean;
  /** Callback to modify custom field. `value` is null when the field is deleted */
  onChange: (change: { customFieldValueId: string; value: string | null }) => Awaitable<void>;
  /** Hook to fetch the data for a userId */
  useUser: (userId: string | null) => { data?: User; isLoading: boolean };
  /** Callback to search for users */
  searchUsers: (searchText: string) => Awaitable<User[]>;
  /** Callback to search for custom fields */
  searchCustomFields: (searchText: string) => Awaitable<CustomField[]>;
  /** Callback to create new custom field */
  addCustomField: (entry: {
    name: string;
    description: string;
    type: CustomFieldType;
  }) => Awaitable<void>;
  /** Callback to edit custom field */
  editCustomField: (entry: {
    id: string;
    name?: string;
    description?: string;
    type?: CustomFieldType;
  }) => Awaitable<void>;
  /** Callback to delete custom field */
  deleteCustomField: (id: string) => Awaitable<void>;
  /** Callback to create new custom field value */
  addCustomFieldValue: (entry: { customFieldId: string; value: string }) => Awaitable<void>;
  onDeleteFieldValue: (fieldId: string) => Awaitable<void>;
  /** Parent Object ID */
  parentObjectId?: string;
  disabled?: boolean;
  editing?: boolean;
};

export const CustomFieldForm = (props: CustomFieldFormProps) => {
  const {
    customFieldValues: _customFieldValues,
    onChange,
    onDeleteFieldValue,
    useUser,
    searchUsers,
    searchCustomFields,
    addCustomField,
    editCustomField,
    deleteCustomField,
    addCustomFieldValue,
    disabled = false,
    parentObjectId,
    editing,
  } = props;
  const [addModalopen, setAddModalOpen] = useState<boolean>(false);
  const [fieldUnderDelete, setFieldUnderDelete] = useState<string | null>(null);
  const theme = useTheme();

  const customFieldValuesHookResult = useCustomFieldValues(parentObjectId ?? "");
  const { data: customFieldValues, isLoading } = !disabled
    ? customFieldValuesHookResult
    : { data: _customFieldValues, isLoading: false };

  const hasCustomFieldValues = customFieldValues && customFieldValues.length > 0;
  const searchAddableCustomFields = async (searchText: string) => {
    const fields = await searchCustomFields(searchText);
    // don't include custom fields that are already present
    return fields.filter(
      (customField) =>
        !customFieldValues?.some((fieldValue) => fieldValue.field_id === customField.id)
    );
  };
  const fieldCount = customFieldValues?.length ?? 0;
  return (
    <SecondaryBox>
      <Stack gap="10px">
        {isLoading || !!!customFieldValues ? (
          <CustomFieldLoader />
        ) : (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h3">
                Custom Fields {fieldCount > 0 ? `(${fieldCount})` : null}
              </Typography>
              {editing && (
                <LoadingButton
                  variant="contained"
                  onClick={() => setAddModalOpen(true)}
                  disabled={disabled || !editing}
                  sx={{
                    width: "100px",
                    height: "30px",
                  }}
                >
                  Add
                </LoadingButton>
              )}
            </Box>
            {hasCustomFieldValues &&
              customFieldValues.map((customFieldValue) => (
                <Box key={customFieldValue.id} display="flex">
                  <CustomFieldInput
                    key={customFieldValue.id}
                    customFieldValueId={customFieldValue.id}
                    onChange={onChange}
                    useUser={useUser}
                    searchUsers={searchUsers}
                    addCustomField={addCustomField}
                    editCustomField={editCustomField}
                    deleteCustomFieldValue={onDeleteFieldValue}
                    disabled={disabled || !editing}
                  />
                </Box>
              ))}
          </>
        )}
      </Stack>
      <CustomFieldValueAddModal
        open={addModalopen}
        onClose={() => setAddModalOpen(false)}
        useUser={useUser}
        searchUsers={searchUsers}
        searchCustomFields={searchAddableCustomFields}
        addCustomField={addCustomField}
        editCustomField={editCustomField}
        deleteCustomField={deleteCustomField}
        addCustomFieldValue={addCustomFieldValue}
      />
      <ConfirmationModal
        open={fieldUnderDelete != null}
        onClose={() => setFieldUnderDelete(null)}
        title="Remove Custom Field"
        description="Are you sure you want to remove custom field?
             Once it’s deleted it’s gone for good"
        acceptText="Yes, remove custom field"
        onAccept={async () => {
          onDeleteFieldValue(fieldUnderDelete!);
        }}
      />
    </SecondaryBox>
  );
};

type CustomFieldAddModalProps = {
  open: boolean;
  onClose: () => void;
  customFieldId?: string;
  addCustomField?: (entry: {
    name: string;
    description: string;
    type: CustomFieldType;
  }) => Awaitable<void>;
  editCustomField?: (entry: {
    id: string;
    name?: string;
    description?: string;
    type?: CustomFieldType;
    key?: string;
    placeholder?: string;
  }) => Awaitable<void>;
};

export const CustomFieldAddModal = (props: CustomFieldAddModalProps) => {
  const {
    open,
    onClose,
    customFieldId,
    addCustomField: customAddCustomField,
    editCustomField,
  } = props;
  const [name, setName, nameError, setNameError] = useInputState<string>("");
  const [key, setKey] = useState<string>("");
  const [placeholder, setPlaceholder] = useState<string>("");
  const [type, setType] = useState<CustomFieldType>("String");
  const [description, setDescription] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { user } = useAuth();
  const isAdmin = !!user?.is_fairo_admin;

  const handleSave = async () => {
    if (!name.trim()) {
      setNameError(true);
      return;
    }

    setLoading(true);
    if (editCustomField != null && customFieldId != null) {
      try {
        await editCustomField({
          id: customFieldId,
          name,
          description,
          type,
          ...(isAdmin ? { key, placeholder } : {}),
        });
        openSnackbar("Custom field updated successfully", "success");
        onClose();
      } catch (e) {
        const errorMessage = getAPIErrorMessage(e as any, "Failed to update custom field");
        openSnackbar(errorMessage, "error");
      } finally {
        setLoading(false);
      }
      return;
    }
    try {
      if (customAddCustomField) {
        await customAddCustomField({
          name,
          description,
          type,
        });
      } else {
        await addCustomField("", {
          field_name: name,
          description,
          field_type: type,
          ...(isAdmin ? { field_key: key, placeholder } : {}),
        });
      }
      onClose();
    } catch (e) {
      const errorMessage = getAPIErrorMessage(e as any, "Failed to create custom field");
      openSnackbar(errorMessage, "error");
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomFieldDetails = async () => {
    try {
      if (customFieldId) {
        const data = await fetchCustomField(customFieldId);
        setType(data.field_type);
        setName(data.field_name);
        setDescription(data.description);
        setIsLoading(false);
        setKey(data.field_key);
        setPlaceholder(data?.placeholder ?? "");
      }
    } catch (error) {
      if (error instanceof CustomAxiosError) {
        error.showAuditLogSnackbar("Failed to fetch custom field");
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (customFieldId) {
      fetchCustomFieldDetails();
    } else {
      setType("String");
      setName("");
      setDescription("");
      setKey("");
      setIsLoading(false);
      setPlaceholder("");
    }
  }, [customFieldId]);

  const typeOptions: string[] = [
    "String",
    "Number",
    "JSON",
    "Date",
    "User",
    "Asset list",
    "User list",
    "String list",
    "Tag List",
    "Formatted Text",
    "Reference list",
    "File or Link",
    "Asset with Description list",
    "Metric with Description list",
    "Metric Result Test Result list",
    "Number with Unit",
    "Color Scheme",
    "Metric Result list",
  ];

  return (
    <SmallModal
      open={open}
      onClose={onClose}
      title={customFieldId ? "Edit Custom Field" : "New Custom Field"}
      isLoading={isLoading}
    >
      <Stack gap="10px">
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Field Name"
          required
          error={nameError}
        />
        {isAdmin && (
          <TextField
            value={key}
            onChange={(e) => setKey(e.target.value)}
            label="Field Key"
            required
            error={false}
          />
        )}
        {isAdmin && (
          <TextField
            value={placeholder}
            onChange={(e) => setPlaceholder(e.target.value)}
            label="Placeholder"
            required
            error={false}
          />
        )}
        <SearchableDropdown
          label="Type"
          value={type}
          onChange={(e) => setType(e as CustomFieldType)}
          getOptions={(search: string) =>
            typeOptions.filter((option) => option.toLowerCase().includes(search.toLowerCase()))
          }
          getOptionLabel={(option) => option}
          isOptionEqualToValue={(a, b) => a === b}
        />
        <TextField
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          label="Description"
          multiline
          minRows={3}
        />
        <Box>
          <LoadingButton fullWidth variant="contained" loading={loading} onClick={handleSave}>
            {customFieldId ? "Save" : "Create"}
          </LoadingButton>
        </Box>
      </Stack>
    </SmallModal>
  );
};

type CustomFieldValueAddModalProps = {
  open: boolean;
  onClose: () => void;
  useUser: (userId: string | null) => { data?: User; isLoading: boolean };
  searchUsers: (searchText: string) => Awaitable<User[]>;
  searchCustomFields: (searchText: string) => Awaitable<CustomField[]>;
  addCustomField: (entry: {
    name: string;
    description: string;
    type: CustomFieldType;
  }) => Awaitable<void>;
  editCustomField: (entry: {
    id: string;
    name?: string;
    description?: string;
    type?: CustomFieldType;
  }) => Awaitable<void>;
  deleteCustomField: (id: string) => Awaitable<void>;
  addCustomFieldValue: (entry: { customFieldId: string; value: string }) => Awaitable<void>;
};

const CustomFieldValueAddModal = (props: CustomFieldValueAddModalProps) => {
  const {
    open,
    onClose,
    searchCustomFields,
    searchUsers,
    useUser,
    addCustomField,
    editCustomField,
    deleteCustomField,
    addCustomFieldValue,
  } = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const [customField, _setCustomField] = useState<CustomField | null>(null);
  const [value, setValue, valueError, setValueError] = useInputState<string>("");
  const setCustomField = (val: CustomField | null) => {
    _setCustomField(val);
    if (val == null) {
      return;
    }
    // set default value based on custom field type
    const defaultValue = (() => {
      const field_type = val.field_type as Exclude<
        CustomFieldType,
        | "Asset"
        | "Formatted Text"
        | "Asset List"
        | "User List"
        | "String List"
        | "File"
        | "Hyperlink"
        | "Reference List"
      >;
      switch (field_type) {
        case "User":
        case "String":
        case "Number":
          return "";
        case "JSON":
          return "{}";
        case "Date":
          return moment().toISOString();
        case "Tag List":
          return "[]";
        case "Color Scheme":
          return "[]";
        case "Metric Result list":
          return "[]";
        default:
          // below line makes sure the switch case handles all custom field types
          const _: never = field_type;
          return "";
      }
    })();
    setValue(defaultValue);
  };
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);

  const handleSave = async () => {
    if (customField == null) {
      return;
    }
    // validations
    if (customField.field_type === "JSON") {
      try {
        JSON.parse(value);
      } catch {
        setValueError(true);
        setErrorMessage("Invalid JSON");
        return;
      }
    } else if (customField.field_type === "Number") {
      if (!value || isNaN(Number(value)) || isNaN(parseFloat(value))) {
        setValueError(true);
        setErrorMessage("Not a Number");
        return;
      }
    } else if (["User", "Date", "String"].includes(customField.field_type)) {
      if (!value) {
        setValueError(true);
        setErrorMessage("Cannot be empty");
        return;
      }
    }

    setLoading(true);
    try {
      await addCustomFieldValue({ customFieldId: customField.id, value });
      setCustomField(null);
      onClose();
    } catch (e) {
      const errorMessage = getAPIErrorMessage(e as any, "Failed to add custom field value");
      if (e instanceof CustomAxiosError) {
        e.showAuditLogSnackbar(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (customField == null) {
      return;
    }
    setLoading(true);
    try {
      await deleteCustomField(customField.id);
      setCustomField(null);
      openSnackbar("Custom field deleted successfully", "success");
      onClose();
    } catch (e) {
      const errorMessage = getAPIErrorMessage(e as any, "Failed to delete custom field");
      if (e instanceof CustomAxiosError) {
        e.showAuditLogSnackbar(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const typeFontColor = isDarkMode
    ? theme.palette.custom.secondary
    : theme.palette.custom.blue[400];

  return (
    <>
      <SmallModal open={open} onClose={onClose} title="Add Custom Field">
        <Stack gap="10px">
          <SearchableDropdown<CustomField>
            value={customField}
            onChange={setCustomField}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            getOptions={searchCustomFields}
            getOptionLabel={(customField) => customField.field_name}
            renderOption={(props, option) => {
              return (
                <Box
                  component="li"
                  {...props}
                  key={option.id}
                  width="392px"
                  display="flex"
                  gap="10px"
                  padding="1px 5px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box
                    sx={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h4" color={theme.palette.text.primary}>
                      {option.field_name}
                    </Typography>
                    {option.description && (
                      <Typography variant="body2">
                        <span style={{ color: typeFontColor }}>(Type: {option.field_type})</span>{" "}
                        {breakDescriptionIn250Chars(option.description)}
                      </Typography>
                    )}
                  </Box>
                  {config.ids.fairoUserUUID !== (option.created_by ?? "") && (
                    <>
                      <IconButton
                        onClick={() => {
                          _setCustomField(option);
                          setCreateModalOpen(true);
                        }}
                      >
                        {!isDarkMode ? (
                          <EditLightIcon width="16px" height="17px" />
                        ) : (
                          <EditDarkIcon width="16px" height="17px" />
                        )}
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setDeleteConfirmationOpen(true);
                        }}
                      >
                        <DeleteIcon
                          width="16px"
                          height="17px"
                          color={theme.palette.custom.redTypography}
                        />
                      </IconButton>
                    </>
                  )}
                </Box>
              );
            }}
            actionButton={["New Custom Field", () => setCreateModalOpen(true)]}
          />
          {customField != null && (
            <>
              {customField.description && (
                <Typography variant="body2" color={theme.palette.custom.secondaryTypography}>
                  {customField.description}
                </Typography>
              )}
              {(() => {
                const field_type = customField.field_type as Exclude<
                  CustomFieldType,
                  | "Asset"
                  | "Formatted Text"
                  | "Asset List"
                  | "User List"
                  | "String List"
                  | "File"
                  | "Hyperlink"
                  | "Reference List"
                >;
                switch (field_type) {
                  case "String":
                    return (
                      <StringCustomField
                        value={value}
                        onChange={setValue}
                        error={valueError}
                        errorMessage={errorMessage}
                      />
                    );
                  case "Number":
                    return (
                      <NumberCustomField
                        value={value}
                        onChange={setValue}
                        error={valueError}
                        errorMessage={errorMessage}
                      />
                    );
                  case "JSON":
                    return (
                      <JSONCustomField
                        value={value}
                        onChange={setValue}
                        error={valueError}
                        errorMessage={errorMessage}
                      />
                    );
                  case "Date":
                    return (
                      <DateCustomField
                        value={value}
                        onChange={setValue}
                        error={valueError}
                        errorMessage={errorMessage}
                      />
                    );
                  case "User":
                    return (
                      <UserCustomField
                        value={value}
                        useUser={useUser}
                        searchUsers={searchUsers}
                        onChange={setValue}
                        error={valueError}
                        errorMessage={errorMessage}
                      />
                    );
                  case "Tag List":
                    return (
                      <TagListCustomField
                        value={
                          typeof value === "string" && value !== "undefined"
                            ? JSON.parse(value.replace(/'/g, '"'))
                            : []
                        }
                        onChange={(tag) => {
                          setValue(JSON.stringify(tag));
                        }}
                        search={searchTags}
                        error={valueError}
                        errorMessage={errorMessage}
                      />
                    );
                  case "Color Scheme":
                    return (
                      <ColorSchemeCustomField value={value} onChange={(rules) => setValue(rules)} />
                    );
                  case "Metric Result list":
                    return (
                      <CustomInputValidation
                        value={
                          typeof value === "string" && value !== "undefined"
                            ? JSON.parse(value.replace(/'/g, '"'))
                            : []
                        }
                        onChange={(results) => {
                          setValue(JSON.stringify(results));
                        }}
                        valueError={valueError}
                        errorMessage={errorMessage}
                        field_type="Metric Result list"
                      />
                    );
                  default:
                    // below line makes sure the switch case handles all custom field types
                    const _: never = field_type;
                }
              })()}
            </>
          )}
          <Box>
            <LoadingButton
              fullWidth
              variant="contained"
              disabled={customField == null}
              loading={loading}
              onClick={handleSave}
            >
              Save
            </LoadingButton>
          </Box>
        </Stack>
      </SmallModal>
      <CustomFieldAddModal
        open={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
          _setCustomField(null);
        }}
        customFieldId={customField?.id}
        addCustomField={addCustomField}
        editCustomField={editCustomField}
      />
      <ConfirmationModal
        open={deleteConfirmationOpen}
        title={"Delete Custom Field"}
        description={`Are you sure you want to delete Custom Field-${customField?.field_name}? Once it’s deleted it’s gone for good`}
        onAccept={handleDelete}
        onClose={() => {
          setDeleteConfirmationOpen(false);
          _setCustomField(null);
        }}
        acceptText={"Delete"}
        rejectText="Cancel"
      />
    </>
  );
};

export const CustomFieldInput = (props: {
  customFieldValueId: string;
  onChange: (change: { customFieldValueId: string; value: any | null }) => Awaitable<void>;
  useUser: (userId: string | null) => { data?: User; isLoading: boolean };
  searchUsers: (searchText: string) => Awaitable<User[]>;
  addCustomField: (entry: {
    name: string;
    description: string;
    type: CustomFieldType;
  }) => Awaitable<void>;
  editCustomField: (entry: {
    id: string;
    name?: string;
    description?: string;
    type?: CustomFieldType;
  }) => Awaitable<void>;
  deleteCustomFieldValue: (fieldId: string) => Awaitable<void>;
  disabled?: boolean;
}) => {
  const {
    customFieldValueId,
    useUser,
    searchUsers,
    onChange,
    addCustomField,
    editCustomField,
    deleteCustomFieldValue,
    disabled = false,
  } = props;

  const { data: customFieldValue, isLoading } = useCustomFieldValue(customFieldValueId ?? "");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue, valueError, setValueError] = useInputState<string>(
    String(customFieldValue?.value)
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { data: user } = useUser(
    customFieldValue?.field_type === "User" ? customFieldValue.value : null
  );
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [customField, _setCustomField] = useState<CustomField | null>(null);
  const [fieldUnderDelete, setFieldUnderDelete] = useState<string | null>(null);

  const setCustomField = (val: CustomField | null) => {
    _setCustomField(val);
    if (val == null) {
      return;
    }
    // set default value based on custom field type
    const defaultValue = (() => {
      const field_type = val.field_type as Exclude<
        CustomFieldType,
        | "Asset"
        | "Asset List"
        | "User List"
        | "String List"
        | "File"
        | "Hyperlink"
        | "Reference List"
      >;
      switch (field_type) {
        case "User":
        case "String":
        case "Number":
          return "";
        case "JSON":
          return "{}";
        case "Date":
          return moment().toISOString();
        case "Tag List":
          return "[]";
        case "Formatted Text":
          return "";
        case "Color Scheme":
          return "[]";
        case "Metric Result list":
          return "[]";
        default:
          // below line makes sure the switch case handles all custom field types
          const _: never = field_type;
          return "";
      }
    })();
    setValue(defaultValue);
  };
  const displayValue = (() => {
    const field_type = customFieldValue?.field_type as Exclude<
      CustomFieldType,
      "Asset" | "Asset List" | "User List" | "String List" | "File" | "Hyperlink" | "Reference List"
    >;
    switch (field_type) {
      case "String":
      case "Number":
      case "JSON":
        return customFieldValue?.value;
      case "Date":
        return dateDisplay(customFieldValue?.value);
      case "User":
        return user == null ? <CircularProgress /> : user.name;
      case "Tag List":
        return customFieldValue?.value;
      case "Formatted Text":
        return customFieldValue?.value;
      case "Color Scheme":
        return customFieldValue?.value;
      case "Metric Result list":
        return customFieldValue?.value;
      default:
        const _: never = field_type;
    }
  })();

  const handleSave = async () => {
    // validations
    if (customFieldValue?.field_type === "JSON") {
      try {
        JSON.parse(value);
      } catch {
        setValueError(true);
        setErrorMessage("Invalid JSON");
        return;
      }
    } else if (["User", "Date", "String"].includes(customFieldValue?.field_type as string)) {
      if (!value) {
        setValueError(true);
        setErrorMessage("Cannot be empty");
        return;
      }
    }

    setLoading(true);
    try {
      await onChange({ customFieldValueId: customFieldValue?.id ?? "", value });
      setIsOpen(false);
    } catch (error) {
      if (error instanceof CustomAxiosError) {
        error.showAuditLogSnackbar("Failed to modify custom field");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box width="100%" display="flex" flexDirection="column" gap="5px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
            {customFieldValue?.field_name}
          </Typography>
          {!disabled && (
            <Box display="flex" gap="5px">
              <IconButton
                onClick={() => {
                  setIsOpen(true);
                  setValue(String(customFieldValue?.value));
                }}
                disabled={disabled}
              >
                <NavbarIcon
                  variant="edit-icon"
                  selected={true}
                  sx={{ width: "15px", height: "15px" }}
                  color={disabled ? theme.palette.custom.grayTypography : ""}
                />
              </IconButton>
              <IconButton
                onClick={() => setFieldUnderDelete(customFieldValue?.id ?? "")}
                disabled={disabled}
              >
                <DeleteIcon
                  width="15px"
                  height="15px"
                  color={
                    disabled
                      ? theme.palette.custom.grayTypography
                      : theme.palette.custom.redTypography
                  }
                />
              </IconButton>
            </Box>
          )}
        </Box>
        {(() => {
          const field_type = customFieldValue?.field_type;
          switch (field_type) {
            case "String":
              return (
                <ShowMore
                  description={displayValue?.toString() ?? ""}
                  maxChar={250}
                  richText={false}
                />
              );
            case "Number":
              return <Typography variant="body2">{displayValue?.toString() ?? ""}</Typography>;
            case "JSON":
              return displayValue?.toString() !== "" ? (
                <JSONView value={displayValue?.toString() ?? ""} />
              ) : (
                <Typography variant="body2">{displayValue?.toString() ?? ""}</Typography>
              );
            case "Date":
              return (
                <Typography variant="body2">
                  {defaultDateDisplay(customFieldValue?.value?.toString() ?? "")}
                </Typography>
              );
            case "User":
              return <UserTag name={displayValue?.toString() ?? ""} nameTypo="body2" />;
            case "Tag":
              return (
                <TagListReadOnlyCustomField
                  value={typeof displayValue === "string" ? [displayValue] : []}
                />
              );
            case "Tag List":
              return (
                <TagListReadOnlyCustomField
                  value={
                    typeof displayValue === "string" && isJSON(displayValue.replace(/'/g, '"'))
                      ? JSON.parse(displayValue.replace(/'/g, '"'))
                      : []
                  }
                />
              );
            case "Formatted Text":
              return (
                <ShowMore
                  description={typeof displayValue === "string" ? displayValue : ""}
                  maxChar={250}
                  richText={true}
                />
              );
            case "Color Scheme":
              return (
                <StyleRules
                  rules={
                    typeof displayValue === "string" && isJSON(displayValue)
                      ? JSON.parse(displayValue)
                      : value
                  }
                  onEdit={() => {}}
                  onRemove={() => {}}
                  disabled={true}
                />
              );
            case "Metric Result list":
              return (
                <MetricResultListReadOnlyCustomField
                  value={
                    typeof displayValue === "string" && isJSON(displayValue.replace(/'/g, '"'))
                      ? JSON.parse(displayValue.replace(/'/g, '"'))
                      : []
                  }
                />
              );
          }
        })()}
      </Box>
      <CustomFieldAddModal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          _setCustomField(null);
        }}
        customFieldId={customField?.id}
        addCustomField={addCustomField}
        editCustomField={editCustomField}
      />
      <ConfirmationModal
        open={fieldUnderDelete != null}
        onClose={() => setFieldUnderDelete(null)}
        title="Remove Custom Field"
        description="Are you sure you want to remove custom field?
             Once it’s deleted it’s gone for good"
        acceptText="Yes, remove custom field"
        onAccept={async () => {
          deleteCustomFieldValue(fieldUnderDelete!);
        }}
      />
      <SmallModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Update Custom Field"
        isLoading={isLoading}
      >
        <Stack gap="10px">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography variant="h2" color={theme.palette.custom.gray}>
                {customFieldValue?.field_name}
              </Typography>
              <Typography variant="h3" color={theme.palette.custom.gray}>
                {customFieldValue?.description}
              </Typography>
            </Box>
            {config.ids.fairoUserUUID !== customFieldValue?.field_created_by && (
              <IconButton
                onClick={() => {
                  _setCustomField({
                    id: customFieldValue?.field_id ?? "",
                    field_name: customFieldValue?.field_name ?? "",
                    field_type: customFieldValue?.field_type as CustomFieldType,
                    description: customFieldValue?.description ?? "",
                    created_by: customFieldValue?.field_created_by,
                    field_key: customFieldValue?.field_key ?? "",
                  });
                  setEditModalOpen(true);
                }}
              >
                {!isDarkMode ? (
                  <EditLightIcon width="16px" height="17px" />
                ) : (
                  <EditDarkIcon width="16px" height="17px" />
                )}
              </IconButton>
            )}
          </Box>
          {(() => {
            const field_type = customFieldValue?.field_type as Exclude<
              CustomFieldType,
              | "Asset"
              | "Asset List"
              | "User List"
              | "String List"
              | "File"
              | "Hyperlink"
              | "Reference List"
            >;
            switch (field_type) {
              case "String":
                return (
                  <StringCustomField
                    value={value}
                    onChange={setValue}
                    error={valueError}
                    errorMessage={errorMessage}
                  />
                );
              case "Number":
                return (
                  <NumberCustomField
                    value={value}
                    onChange={setValue}
                    error={valueError}
                    errorMessage={errorMessage}
                  />
                );
              case "JSON":
                return (
                  <JSONCustomField
                    value={value}
                    onChange={setValue}
                    error={valueError}
                    errorMessage={errorMessage}
                  />
                );
              case "Date":
                return (
                  <DateCustomField
                    value={value}
                    onChange={setValue}
                    error={valueError}
                    errorMessage={errorMessage}
                  />
                );
              case "User":
                return (
                  <UserCustomField
                    value={value}
                    useUser={useUser}
                    searchUsers={searchUsers}
                    onChange={setValue}
                    error={valueError}
                    errorMessage={errorMessage}
                  />
                );
              case "Tag List":
                return (
                  <TagListCustomField
                    value={
                      typeof value === "string" && value !== "undefined"
                        ? JSON.parse(value.replace(/'/g, '"'))
                        : []
                    }
                    onChange={(tag) => {
                      setValue(JSON.stringify(tag));
                    }}
                    search={searchTags}
                    error={valueError}
                    errorMessage={errorMessage}
                  />
                );
              case "Formatted Text":
                return <CKEditorField value={value} onChange={setValue} />;
              case "Color Scheme":
                return (
                  <ColorSchemeCustomField value={value} onChange={(rules) => setValue(rules)} />
                );
              case "Metric Result list":
                return (
                  <CustomInputValidation
                    value={
                      typeof value === "string" && value !== "undefined"
                        ? JSON.parse(value.replace(/'/g, '"'))
                        : []
                    }
                    onChange={(results) => {
                      setValue(JSON.stringify(results));
                    }}
                    valueError={valueError}
                    errorMessage={errorMessage}
                    field_type="Metric Result list"
                  />
                );
              default:
                // below line makes sure the switch case handles all custom field types
                const _: never = field_type;
            }
          })()}
          <Box>
            <LoadingButton variant="contained" fullWidth onClick={handleSave} loading={loading}>
              Save
            </LoadingButton>
            <Button fullWidth variant="text" color="error" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
          </Box>
        </Stack>
      </SmallModal>
    </>
  );
};

export const StringCustomField = (
  props: FieldHandlerProps & {
    fullWidth?: boolean;
    maxRows?: number;
    multiline?: boolean;
    minRows?: number;
  }
) => {
  const {
    value,
    onChange,
    error,
    errorMessage,
    label = "Text",
    required = true,
    rows = 1,
    maxRows,
    multiline = true,
    disabled = false,
    fullWidth = true,
    minRows,
  } = props;

  return (
    <TextField
      label={label}
      required={required}
      multiline={multiline}
      maxRows={maxRows ?? 10}
      fullWidth={fullWidth}
      error={error}
      helperText={error ? errorMessage : null}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      minRows={minRows}
      // rows={rows}
    />
  );
};

export const StringListCustomField = (props: FieldHandlerProps) => {
  const { value, onChange, required, label } = props;
  const values = typeof value === "string" && isJSON(value) ? JSON.parse(value) : value;

  return (
    <SearchableDropdown<string>
      label={label}
      required={required}
      value={values}
      multiple={true}
      onChange={onChange}
      getOptions={async (searchText) => [searchText]}
      hideDropdown={true}
      isOptionEqualToValue={(a, b) => a === b}
      getOptionLabel={(option) => option}
    />
  );
};

export const NumberCustomField = (props: FieldHandlerProps) => {
  const { value, onChange, error, errorMessage, required = true } = props;

  return (
    <TextField
      label="Number"
      required={required}
      error={error}
      helperText={error ? errorMessage : null}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export const JSONCustomField = (props: FieldHandlerProps & { showJSONModal?: boolean }) => {
  const { value, onChange, error, errorMessage, required, label, showJSONModal = false } = props;
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);

  const showValueParsed = (value: string) => {
    try {
      const jsonObj = JSON.parse(value);
      return JSON.stringify(jsonObj, null, 2);
    } catch (error) {
      return value;
    }
  };

  return showJSONModal ? (
    <>
      <UpdateJSONModal
        open={open}
        onClose={() => setOpen(false)}
        required={required}
        value={value}
        onChange={onChange}
        error={error}
        errorMessage={errorMessage}
        label={label}
      />
      <JSONTableView
        json={value}
        keyName="key"
        keyColumnHeader="Parameter Name"
        valueName="value"
        valueColumnHeader="Parameter Value"
        editing={true}
        setOpen={() => setOpen(true)}
      />
    </>
  ) : (
    <TextField
      label={label ?? "JSON"}
      InputProps={{
        style: { fontFamily: "'Fira Code', monospace", fontSize: "14px", fontWeight: 400 },
      }}
      required={required}
      multiline={true}
      maxRows={10}
      error={error}
      helperText={error ? errorMessage : null}
      value={showValueParsed(value)}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export const DateCustomField = (
  props: FieldHandlerProps & { allowEmpty?: boolean; minDate?: string; timePicker?: boolean }
) => {
  const {
    value,
    onChange,
    error,
    errorMessage,
    required = true,
    allowEmpty,
    minDate,
    timePicker,
  } = props;
  const handleDateChange = (val: any) => {
    try {
      onChange(format(val.toDate(), "yyyy-MM-dd"));
    } catch (e) {
      onChange(val);
    }
  };
  return (
    <CustomCalendar
      value={value}
      timePicker={timePicker}
      onChange={handleDateChange}
      label="Date"
      allowEmpty={allowEmpty}
      minDate={minDate}
      renderInput={(params) => (
        <TextField
          required={required}
          {...params}
          error={allowEmpty ? false : error || params.error}
          helperText={allowEmpty ? null : errorMessage || params.errorMessage}
        />
      )}
    />
  );
};

export const UserCustomField = (
  props: FieldHandlerProps & {
    useUser: (userId: string | null) => { data?: User; isLoading: boolean };
    searchUsers: (searchText: string) => Awaitable<User[]>;
    setObjectAttributes?: (obj: any) => void;
    label?: string;
    fullWidth?: boolean;
    onBlur?: () => void;
    autoFocus?: boolean;
  }
) => {
  const {
    value,
    useUser,
    searchUsers,
    onChange,
    error,
    setObjectAttributes,
    required,
    label = "User",
    fullWidth = true,
    onBlur,
    autoFocus = false,
  } = props;
  const { data: user } = useUser(value || null);
  useEffect(() => {
    if (user && setObjectAttributes) {
      setObjectAttributes(user ?? {});
    }
  }, [user]);
  return (
    <SearchableDropdown<User>
      label={label}
      onBlur={onBlur}
      required={required}
      error={error}
      fullWidth={fullWidth}
      value={value && user ? user : null}
      onChange={(newUser) => (newUser != null ? onChange(newUser.id) : onChange(null))}
      getOptions={searchUsers}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(user) => [user.name, user.username]}
    />
  );
};

export const UserListCustomField = (
  props: FieldHandlerProps & {
    useUsers: (params: UserFetchOptions) => { data?: ListResponse<User>; isLoading: boolean };
    searchUsers: (searchText: string) => Awaitable<User[]>;
    setObjectAttributes?: (obj: any) => void;
    fullWidth?: boolean;
    acceptString?: boolean;
  }
) => {
  const {
    value,
    searchUsers,
    onChange,
    error,
    useUsers,
    setObjectAttributes,
    required,
    fullWidth = true,
    acceptString = false,
  } = props;
  const validIds =
    typeof value === "string" && isJSON(value)
      ? JSON.parse(value).filter(isValidUUID)
      : value.filter(isValidUUID);
  const invalidIds =
    typeof value === "string" && isJSON(value)
      ? JSON.parse(value).filter((v: any) => !isValidUUID(v))
      : value.filter((user: any) => !isValidUUID(user));
  const { data: users } = useUsers({ id: validIds });
  useEffect(() => {
    if (users && users.results && users.results.length && setObjectAttributes) {
      setObjectAttributes(users.results[0] ?? {});
    }
  }, [users]);
  const combinedUsers = [
    ...(validIds.length > 0 ? users?.results ?? [] : []),
    ...invalidIds.map((user: any) => ({
      id: user,
      name: user,
    })),
  ];
  return (
    <SearchableDropdown<User>
      label={props.label ?? "User"}
      required={required}
      error={error}
      multiple={true}
      value={value.length === 0 ? [] : combinedUsers ?? []}
      onChange={(newUser) => newUser != null && onChange(newUser.map((user) => user.id))}
      getOptionLabel={(user) => user.name}
      getOptions={searchUsers}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(user) => [user.name, user.username ?? ""]}
      fullWidth={fullWidth}
      acceptString={acceptString}
    />
  );
};

export const AssetCustomField = (
  props: FieldHandlerProps & {
    useAsset: (assetId: string) => { data?: Asset; isLoading: boolean };
    searchAssets: (searchText: string) => Awaitable<Asset[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, useAsset, searchAssets, onChange, error, setObjectAttributes, required } = props;

  const { data: asset } = useAsset(value || "");
  useEffect(() => {
    if (asset && setObjectAttributes) {
      setObjectAttributes(asset ?? {});
    }
  }, [asset]);
  return (
    <SearchableDropdown<Asset>
      label="Asset"
      required={required}
      error={error}
      value={asset || null}
      onChange={(newAsset) => (newAsset != null ? onChange(newAsset.id) : onChange(null))}
      getOptions={searchAssets}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(asset) => [asset.name, asset.description ?? ""]}
    />
  );
};

export const AssetListCustomField = (
  props: FieldHandlerProps & {
    searchAssets: (searchText: string) => Awaitable<Asset[]>;
    useAssets: (params: AssetFetchOptions) => { data?: ListResponse<Asset>; isLoading: boolean };
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, searchAssets, onChange, error, setObjectAttributes, useAssets, required } = props;
  const parsedValue = typeof value === "string" && isJSON(value) ? JSON.parse(value) : value;
  const { data: assets } = useAssets({ id: parsedValue });
  useEffect(() => {
    if (assets && assets.results && assets.results.length && setObjectAttributes) {
      setObjectAttributes(assets.results[0] ?? {});
    }
  }, [assets]);
  return (
    <SearchableDropdown<Asset>
      label={props.label ?? "Asset"}
      required={required}
      error={error}
      multiple={true}
      value={parsedValue.length === 0 ? [] : assets?.results ?? []}
      onChange={(newValue) => newValue != null && onChange(newValue.map((asset) => asset.id))}
      getOptionLabel={(obj) => obj.name}
      getOptions={searchAssets}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.name, obj?.description ?? ""]}
    />
  );
};

export const BooleanCustomField = (props: FieldHandlerProps) => {
  const { value, onChange, error, required } = props;

  return (
    <Select
      error={error}
      value={value}
      required={required}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      size="small"
    >
      <MenuItem value="false">False</MenuItem>
      <MenuItem value="true">True</MenuItem>
    </Select>
  );
};

export const RatingLevelCustomField = (props: FieldHandlerProps) => {
  const { value, onChange, error, required } = props;
  const [showCreateRatingLevelModal, setShowCreateRatingLevelModal] = useState(false);
  const [selectedRatingOption, setSelectedRatingOption] = useState<string | null>(null);
  const [optionUnderDelete, setOptionUnderDelete] = useState<RatingLevelOption | null>(null);
  const { data: option } = useRatingLevelOption(value || "");
  const theme = useTheme();
  return (
    <>
      <SearchableDropdown<RatingLevelOption>
        label="Rating Level Options"
        value={option || null}
        onChange={(newValue) => (newValue != null ? onChange(newValue.id) : onChange(null))}
        getOptions={searchRatingLevelOptions}
        getOptionLabel={(option) => option.name}
        required={required}
        renderOption={(props, option) => {
          return (
            <Box
              component="li"
              {...props}
              key={option.id}
              width="392px"
              display="flex"
              gap="10px"
              padding="1px 5px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h4" color={theme.palette.text.primary}>
                  {option.name}
                </Typography>
                {config.ids.fairoUserUUID !== option.created_by.id && (
                  <Box>
                    <IconButton
                      onClick={() => {
                        setSelectedRatingOption(option.id);
                        setShowCreateRatingLevelModal(true);
                      }}
                    >
                      <NavbarIcon
                        variant="edit-icon"
                        selected={true}
                        sx={{ width: "16px", height: "17px", minWidth: "16px", minHeight: "17px" }}
                      />
                    </IconButton>
                    <IconButton onClick={() => setOptionUnderDelete(option)}>
                      <NavbarIcon
                        variant="trash-can"
                        sx={{ width: "16px", height: "17px", minWidth: "16px", minHeight: "17px" }}
                        color={theme.palette.custom.redTypography}
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>
          );
        }}
        isOptionEqualToValue={(a, b) => a.id === b.id}
        actionButton={[
          "Create New Option",
          () => {
            setSelectedRatingOption(null);
            setOptionUnderDelete(null);
            setShowCreateRatingLevelModal(true);
          },
        ]}
      />
      <ConfirmationModal
        open={optionUnderDelete != null}
        onClose={() => setOptionUnderDelete(null)}
        title="Remove Rating Level Option"
        description={`Are you sure you want to remove the option ${optionUnderDelete?.name}? Once it’s deleted it’s gone for good`}
        acceptText="Yes, remove option"
        onAccept={async () => {
          await deleteRatingLevelOption(optionUnderDelete!.id);
          setOptionUnderDelete(null);
        }}
      />
      <CreateRatingLevelModal
        open={showCreateRatingLevelModal}
        onClose={() => setShowCreateRatingLevelModal(false)}
        title={`${selectedRatingOption ? "Edit" : "Create"} Rating Level Option`}
        ratingLevelId={selectedRatingOption}
      />
    </>
  );
};

export const MLFlowModelCustomField = (
  props: FieldHandlerProps & {
    searchModels: (searchText: string) => Awaitable<MLFlowModel[]>;
  }
) => {
  const { value, searchModels, onChange, error, required } = props;
  if (typeof value === "string" && value !== "") {
    onChange({ name: value });
  }
  return (
    <SearchableDropdown<MLFlowModel>
      label="Model"
      required={required}
      error={error}
      value={value || null}
      onChange={(newModel) => (newModel != null ? onChange(newModel) : onChange(null))}
      getOptions={searchModels}
      isOptionEqualToValue={(a, b) => a.name === b.name}
      getSummaryDescription={(model) => [model.name, ""]}
      getOptionLabel={(model) => model.name}
    />
  );
};
