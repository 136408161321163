import { DefaultBadge, StatusBadge } from "../../atoms/StatusBadge";
import UserTag from "../../components/UserTag";
import {
  Asset,
  BaseFilterOptions,
  BaseFilters,
  ComplianceFramework,
  FilterOption,
} from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { DefaultBadgeList } from "../../molecules/DefaultBadgeList";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { DisplayReadOnlyCustomField } from "../../organisms/DisplayReadOnlyCustomField";
import { getFrameworkImage } from "../FrameworkIconHelper";
import { stripHTMLTags } from "../common";

export const assetListFilterOptions: FilterOption<BaseFilters>[] = [
  { label: "Asset", value: "asset_num" },
  { label: "Name", value: "name" },
  ...BaseFilterOptions,
];

export const assetListColumnsVisibility: IColumnVisibility<Asset>[] = [
  {
    field: "asset_num",
    headerName: "Asset",
    visible: true,
    columnMinWidth: 400,
    renderer: (asset) => <TableTextWithEllipsis value={asset.name} />,
  },
  {
    field: "type_name",
    headerName: "Type",
    visible: true,
  },
  {
    field: "assignee",
    headerName: "Owner",
    visible: true,
    renderer: (asset) => (asset.assignee_name ? <UserTag name={asset.assignee_name} /> : ""),
  },
  {
    field: "type",
    headerName: "Frameworks",
    visible: true,
    renderer: (asset) => (
      <DefaultBadgeList
        renderIcon={(framework) =>
          getFrameworkImage(
            asset.frameworks.find((f) => f.name === framework) as ComplianceFramework,
            "small"
          )
        }
        badges={asset.frameworks.map((framework) => framework.name)}
      />
    ),
  },
  {
    field: "status",
    headerName: "Status",
    visible: true,
    renderer: (asset) => <StatusBadge status={asset.status as any} />,
  },
];

export const policyListColumnsVisibility: IColumnVisibility<Asset>[] = [
  {
    field: "asset_num",
    headerName: "Policy",
    visible: true,
    columnMinWidth: 400,
    renderer: (asset) => <TableTextWithEllipsis value={asset.name} />,
  },
  {
    field: "assignee",
    headerName: "Owner",
    visible: true,
    renderer: (asset) => (asset?.assignee_name ? <UserTag name={asset.assignee_name} /> : ""),
  },
  {
    field: "type",
    headerName: "Frameworks",
    visible: true,
    columnMinWidth: 170,
    columnMaxWidth: 170,
    renderer: (asset) => (
      <DefaultBadgeList
        renderIcon={(framework) =>
          getFrameworkImage(
            asset.frameworks.find((f) => f.name === framework) as ComplianceFramework,
            "small"
          )
        }
        badges={asset.frameworks.map((framework) => framework.name)}
      />
    ),
  },
  {
    field: "status",
    headerName: "Status",
    visible: true,
    renderer: (asset) => {
      if (asset.test_status !== "N/A") {
        return <StatusBadge status={asset.test_status as any} />;
      }
      return <StatusBadge status={asset.status} />;
    },
  },
];

export const documentationListColumnsVisibility: IColumnVisibility<Asset>[] = [
  {
    field: "asset_num",
    headerName: "Documentation",
    visible: true,
    columnMinWidth: 400,
    renderer: (asset) => <TableTextWithEllipsis value={asset.name} />,
  },
  {
    field: "assignee",
    headerName: "Owner",
    visible: true,
    renderer: (asset) => (asset?.assignee_name ? <UserTag name={asset?.assignee_name} /> : ""),
  },
  {
    field: "type",
    headerName: "Frameworks",
    visible: true,
    renderer: (asset) => (
      <DefaultBadgeList
        renderIcon={(framework) =>
          getFrameworkImage(
            asset.frameworks.find((f) => f.name === framework) as ComplianceFramework,
            "small"
          )
        }
        badges={asset.frameworks.map((framework) => framework.name)}
      />
    ),
  },
  {
    field: "status",
    headerName: "Status",
    visible: true,
    renderer: (asset) => {
      if (asset.test_status !== "N/A") {
        return <StatusBadge status={asset.test_status as any} />;
      }
      return <StatusBadge status={asset.status} />;
    },
  },
];

export const modelListColumnsVisibility: IColumnVisibility<Asset>[] = [
  {
    field: "name",
    headerName: "Model",
    visible: true,
    columnMinWidth: 400,
    renderer: (asset) => <TableTextWithEllipsis value={asset.name} />,
  },
  {
    field: "source",
    headerName: "Source",
    visible: true,
    columnMinWidth: 160,
    columnMaxWidth: 200,
    renderer: (asset) => (asset?.source ? <DefaultBadge value={asset.source} /> : null),
  },
  {
    field: "model_type",
    headerName: "Model Type",
    visible: true,
    columnMinWidth: 200,
    columnMaxWidth: 280,
    renderer: (asset) =>
      asset?.model_type ? (
        <DisplayReadOnlyCustomField
          padding="0px"
          field_type="String"
          value={asset?.model_type}
          disabled={false}
        />
      ) : null,
  },
  {
    field: "lifecycle_stage",
    headerName: "Lifecycle Stage",
    visible: true,
    columnMinWidth: 200,
    columnMaxWidth: 350,
    renderer: (asset) =>
      asset?.lifecycle_stage ? <DefaultBadge value={asset?.lifecycle_stage} /> : null,
  },
];

export const datasetListColumnsVisibility: IColumnVisibility<Asset>[] = [
  {
    field: "name",
    headerName: "Dataset",
    visible: true,
    columnMinWidth: 400,
    renderer: (asset) => <TableTextWithEllipsis value={asset.name} />,
  },
  {
    field: "description",
    headerName: "Description",
    visible: true,
    columnMinWidth: 400,
    columnMaxWidth: 800,
    renderer: (asset) => (
      <TableTextWithEllipsis expanded value={stripHTMLTags(asset.description)} />
    ),
  },
  {
    field: "source",
    headerName: "Source",
    visible: true,
    columnMinWidth: 160,
    columnMaxWidth: 200,
    renderer: (asset) => (asset?.source ? <DefaultBadge value={asset.source} /> : null),
  },
];
