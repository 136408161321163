import { Box, Typography, useTheme } from "@mui/material";
import { RiskScoreBadge } from "../atoms/StatusBadge";
import UserTag from "../components/UserTag";
import { Question, RegistryFieldType } from "../models/types";
import { AssetListReadOnlyCustomField } from "../molecules/custom-fields/AssetListCustomField";
import { MetricListReadOnlyCustomField } from "../molecules/custom-fields/MetricCustomField";
import { NumberWithUnitReadOnly } from "../molecules/custom-fields/NumberWithUnitCustomField";
import { ReferenceListReadOnlyCustomField } from "../molecules/custom-fields/ReferenceListCustomField";
import { TagListReadOnlyCustomField } from "../molecules/custom-fields/TagListCustomField";
import { UseCaseListReadOnlyCustomField } from "../molecules/custom-fields/UseCaseCustomField";
import { DefaultBadgeList } from "../molecules/DefaultBadgeList";
import { JSONTableView } from "../molecules/JSONTableView";
import { JSONView } from "../molecules/JSONView";
import { ShowMore } from "../molecules/ShowMore";
import { defaultDateDisplay, isJSON, isValidUUID } from "../utilities/UIHelper";
import { AssetBadge } from "./assets/AssetBadge";
import { DisplayElementBadge } from "./compliance/elements/DisplayElementBadge";
import { AssetWithDescriptionListCustomField } from "./custom-fields/AssetWithDescriptionCustomField";
import { DataOriginReadOnly } from "./custom-fields/DataOriginReadOnly";
import { FileOrLinkCustomField } from "./custom-fields/FileOrLinkCustomField";
import { MetricResultTestResultListCustomField } from "./custom-fields/MetricResultTestResultListCustomField";
import { MetricWithDescriptionListCustomField } from "./custom-fields/MetricWithDescriptionCustomField";
import { MultipleSelectionReadOnly } from "./custom-fields/MultipleSelection";
import { PotentialHarmListFieldReadOnly } from "./custom-fields/PotentialHarms";
import { RelationshipComplexityReadOnly } from "./custom-fields/RelationshipComplexityReadOnly";
import { SingleSelectionReadOnly } from "./custom-fields/SingleSelection";

type DisplayReadOnlyCustomFieldProps = {
  field_type: RegistryFieldType;
  question?: Question;
  value: string | string[] | number;
  sx?: any;
  padding?: string;
  disabled: boolean;
  assetType?: string;
  label?: string;
  model?: string;
  maxChar?: number;
  showJSONTableView?: boolean;
};

export const NameAndDescriptionReadOnly = (props: { value: string; disabled: boolean }) => {
  const { value, disabled } = props;
  const parsedValue = JSON.parse(value);
  const disabledTypographyEffect = {
    opacity: disabled ? 0.5 : 1,
  };
  return (
    <Box display="flex" flexDirection="column" gap="5px">
      {parsedValue.map((item: any, index: number) => {
        const obj = JSON.parse(item);
        return (
          <>
            <Typography
              fontWeight={600}
              sx={{
                ...(disabled && disabledTypographyEffect),
              }}
              variant="body2"
            >
              {obj.name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                ...(disabled && disabledTypographyEffect),
              }}
            >
              {obj.description}
            </Typography>
          </>
        );
      })}
    </Box>
  );
};

export const ExternalLink = (props: { value: string; disabled: boolean }) => {
  const { value, disabled } = props;
  const parsedValue = JSON.parse(value);
  const disabledTypographyEffect = {
    opacity: disabled ? 0.5 : 1,
  };
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap="5px">
      {parsedValue.map((item: any, index: number) => {
        const obj = JSON.parse(item);
        return (
          <>
            <Typography
              fontWeight={600}
              sx={{
                ...(disabled && disabledTypographyEffect),
              }}
              variant="body2"
            >
              {obj.description}
            </Typography>
            <Typography
              onClick={() => !disabled && window.open(obj.link, "_blank")}
              variant="body2"
              color={theme.palette.custom.blueTypography}
              sx={{
                cursor: disabled ? "default" : "pointer",
                ...(disabled && disabledTypographyEffect),
              }}
            >
              {obj.link}
            </Typography>
          </>
        );
      })}
    </Box>
  );
};

export const DisplayReadOnlyCustomField = (props: DisplayReadOnlyCustomFieldProps) => {
  const {
    field_type,
    value,
    disabled,
    padding = "10px",
    question,
    assetType,
    label,
    model,
    maxChar = 250,
    showJSONTableView = false,
  } = props;
  const disabledTypographyEffect = {
    opacity: disabled ? 0.5 : 1,
  };
  return (
    <Box paddingLeft={padding}>
      {(() => {
        switch (field_type) {
          case "Date":
            return (
              <Typography
                sx={{
                  ...(disabled && disabledTypographyEffect),
                }}
                variant="body2"
              >
                {typeof value === "string" ? defaultDateDisplay(value) : ""}
              </Typography>
            );
          case "Name and Description list":
            return (
              <NameAndDescriptionReadOnly
                value={typeof value === "string" ? value : ""}
                disabled={disabled}
              />
            );
          case "Potential Harm":
            return (
              <PotentialHarmListFieldReadOnly
                value={typeof value === "string" ? JSON.parse(value) : ""}
                disabled={disabled}
              />
            );
          case "External Link":
            return (
              <ExternalLink value={typeof value === "string" ? value : ""} disabled={disabled} />
            );
          case "Element list":
            return (
              <Box display="flex" flexDirection="row" flexWrap="wrap" gap="10px">
                {Array.isArray(value) &&
                  value.map((item, index) => <DisplayElementBadge key={index} elementId={item} />)}
              </Box>
            );
          case "Tag":
            return <TagListReadOnlyCustomField value={typeof value === "string" ? [value] : []} />;
          case "Tag List":
            return (
              <TagListReadOnlyCustomField
                value={
                  typeof value === "string" && isJSON(value.replace(/'/g, '"'))
                    ? JSON.parse(value.replace(/'/g, '"'))
                    : []
                }
              />
            );
          case "Formatted Text":
            return (
              <ShowMore
                description={typeof value === "string" ? value : ""}
                maxChar={maxChar}
                richText={true}
              />
            );
          case "File or Link":
            return (
              <FileOrLinkCustomField
                value={typeof value === "string" ? value : null}
                relatedObjectId={""}
                onChange={() => {}}
                edit={false}
              />
            );
          case "JSON":
            return typeof value === "string" && isJSON(value) ? (
              showJSONTableView ? (
                <JSONTableView
                  json={value}
                  keyName="key"
                  keyColumnHeader="Parameter Name"
                  valueName="value"
                  valueColumnHeader="Parameter Value"
                />
              ) : (
                <JSONView value={value} />
              )
            ) : (
              <ShowMore description={value.toString()} />
            );
          case "Risk Level":
            return (
              <Box display="flex" alignItems="center" flexDirection="row" gap="10px">
                <RiskScoreBadge size="small" score={Number(value)} />
                <Typography variant="body2">
                  {question?.choices?.find((opt) => opt.value === value)?.label}
                </Typography>
              </Box>
            );
          case "Single Selection":
            return (
              <SingleSelectionReadOnly
                value={typeof value === "string" ? value : ""}
                choices={question?.choices ?? []}
              />
            );
          case "Data Origin":
            return typeof value === "string" ? (
              <DataOriginReadOnly value={JSON.parse(value)} />
            ) : null;
          case "Relationship Complexity":
            return typeof value === "string" ? (
              <RelationshipComplexityReadOnly value={JSON.parse(value)} />
            ) : null;
          case "Number with Unit":
            return <NumberWithUnitReadOnly value={value} />;
          case "Asset list":
          case "Dataset list":
          case "Model list":
            return value ? (
              <AssetListReadOnlyCustomField
                value={typeof value === "string" ? JSON.parse(value.replace(/'/g, '"')) : []}
              />
            ) : (
              <></>
            );
          case "User list":
            const users = typeof value === "string" ? JSON.parse(value.replace(/'/g, '"')) : [];
            return (
              <Box display="flex" flexDirection="row" flexWrap="wrap" gap="5px">
                {users.map((user: any, index: number) => {
                  const validUUID = isValidUUID(user);
                  return (
                    <UserTag
                      key={index}
                      showNameInitials
                      userId={validUUID ? user : undefined}
                      name={user}
                    />
                  );
                })}
              </Box>
            );
          case "String list":
            const strings =
              typeof value === "string" && isJSON(value.replace(/'/g, '"'))
                ? JSON.parse(value.replace(/'/g, '"'))
                : [];
            return <DefaultBadgeList expanded={true} badges={strings} />;
          case "Languages":
            const languages =
              typeof value === "string" && isJSON(value.replace(/'/g, '"'))
                ? JSON.parse(value.replace(/'/g, '"'))
                : [];
            return <DefaultBadgeList badges={languages} />;
          case "Reference list":
            return (
              <ReferenceListReadOnlyCustomField
                value={typeof value === "string" ? JSON.parse(value.replace(/'/g, '"')) : []}
              />
            );
          case "Asset with Description list":
            return typeof value === "string" ? (
              <AssetWithDescriptionListCustomField
                value={value}
                onChange={() => {}}
                assetType={assetType ?? "Dataset"}
                edit={false}
                label={label ?? "Asset"}
              />
            ) : null;
          case "Metric with Description list":
            return typeof value === "string" ? (
              <MetricWithDescriptionListCustomField
                value={value}
                onChange={() => {}}
                edit={false}
                label={label ?? "Metric"}
              />
            ) : null;
          case "Metric Result Test Result list":
            return (
              <MetricResultTestResultListCustomField
                value={typeof value === "string" ? value : ""}
                model={model ?? ""}
              />
            );
          case "Multiple Selection":
            return (
              <MultipleSelectionReadOnly
                value={typeof value === "string" ? value : "[]"}
                choices={question?.choices ?? []}
              />
            );
          case "Asset":
            return typeof value === "string" ? <AssetBadge assetId={value} /> : <></>;
          case "Metric list":
            return (
              <MetricListReadOnlyCustomField
                oldBadge={false}
                value={typeof value === "string" && isJSON(value as any) ? JSON.parse(value) : []}
              />
            );
          case "Use Case list":
            return (
              <UseCaseListReadOnlyCustomField
                value={typeof value === "string" && isJSON(value as any) ? JSON.parse(value) : []}
              />
            );
          default:
            return (
              <Typography
                sx={{
                  ...(disabled && disabledTypographyEffect),
                }}
                variant="body2"
              >
                {value}
              </Typography>
            );
        }
      })()}
    </Box>
  );
};
