import { Box, Stack, Typography, useTheme } from "@mui/material";
import { SmallModal } from "../../../components/SmallModal";
import { useInputState } from "../../../hooks/useInputTextState";
import { useEffect, useState } from "react";
import { StatusBadge } from "../../../atoms/StatusBadge";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import {
  RegistryFieldType,
  VendorProcurementRequirementStatusOptions,
} from "../../../models/types";
import { useVendorProcurementRequirement } from "../../../hooks/useVendorProcurementRequirements";
import { CustomInputValidation } from "../../CustomInputValidation";
import { LoadingButton } from "@mui/lab";
import { openSnackbar } from "../../../context/SnackbarContext";
import { TableTextWithEllipsis } from "../../../molecules/TableTextWithEllipsis";
import { LinkExternalRequirementModal } from "./LinkExternalRequirementModal";
import { useAsset } from "../../../hooks/useAssets";
import { useUser } from "../../../hooks/useUsers";

type CreateVendorProcurementRequirementModalProps = {
  vendorId: string;
  open: boolean;
  onClose: () => void;
  onCreate: (
    vendor: string,
    name: string,
    requirement_type: string,
    requirement: string,
    assignee: string,
    status: string,
    assignee_name?: string
  ) => Promise<void>;
  onEdit: (
    id: string,
    name?: string,
    requirement_type?: string,
    requirement?: string,
    assignee?: string,
    status?: string,
    assignee_name?: string
  ) => Promise<void>;
  vendorProcurementRequirementId?: string;
  vendorProcurementRequirementDetails?: any;
};

export const CreateVendorProcurementRequirementModal = (
  props: CreateVendorProcurementRequirementModalProps
) => {
  const {
    vendorId,
    open,
    onClose,
    onCreate,
    onEdit,
    vendorProcurementRequirementId,
    vendorProcurementRequirementDetails,
  } = props;
  const theme = useTheme();
  const innerTextColor = theme.palette.custom.secondaryTypography;

  const { data: vendorProcurementRequirement, isLoading } = useVendorProcurementRequirement(
    vendorProcurementRequirementDetails ? "" : vendorProcurementRequirementId ?? ""
  );

  const [name, setName, nameError, setNameError] = useInputState(
    vendorProcurementRequirementDetails?.name ?? ""
  );
  const [requirementType, setRequirementType] = useState<RegistryFieldType | null>(
    vendorProcurementRequirementDetails?.requirement_type ?? null
  );
  const [requirement, setRequirement] = useState<any>(
    vendorProcurementRequirementDetails?.requirement ?? null
  );
  const [assignee, setAssignee] = useState(vendorProcurementRequirementDetails?.assignee ?? "");
  const [status, setStatus] = useState<VendorProcurementRequirementStatusOptions | null>(
    vendorProcurementRequirementDetails?.status ?? null
  );
  const [loading, setLoading] = useState(false);
  const [openLinkExternalRequirement, setOpenLinkExternalRequirement] = useState(false);

  const requirementTypeOptions: RegistryFieldType[] = ["Policy", "Hyperlink", "Jira Ticket"];

  const statusOptions: VendorProcurementRequirementStatusOptions[] = ["OK", "NOT MET", "IGNORED"];

  const { data: assigneeDetails, isLoading: isLoadingAssigneeDetails } = useUser(assignee);

  const onCreateCallback = async () => {
    let hasError = false;
    if (!name) {
      setNameError(true);
      hasError = true;
    }

    if (hasError) {
      return;
    }

    try {
      setLoading(true);
      if (vendorProcurementRequirementId) {
        await onEdit(
          vendorProcurementRequirementId,
          name,
          requirementType as string,
          requirement,
          assignee,
          status as string,
          assigneeDetails?.name ?? ""
        );
      } else {
        await onCreate(
          vendorId,
          name,
          requirementType as string,
          requirement,
          assignee,
          status as string,
          assigneeDetails?.name ?? ""
        );
      }
      openSnackbar(
        `Vendor procurement requirement ${vendorProcurementRequirementId ? "updated" : "created"}`,
        "success"
      );
      onClose();
    } catch (error) {
      openSnackbar(
        `Failed to ${
          vendorProcurementRequirementId ? "update" : "create"
        } vendor procurement requirement`,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const { data: policy, isLoading: isLoadingPolicy } = useAsset(
    requirementType === "Policy" ? requirement : ""
  );

  useEffect(() => {
    if (vendorProcurementRequirement) {
      setName(vendorProcurementRequirement.name);
      setRequirementType(vendorProcurementRequirement.requirement_type);
      setRequirement(vendorProcurementRequirement.requirement);
      setAssignee(vendorProcurementRequirement.assignee);
      setStatus(vendorProcurementRequirement.status);
    }
  }, [vendorProcurementRequirement]);

  useEffect(() => {
    if (vendorProcurementRequirementDetails) {
      setName(vendorProcurementRequirementDetails?.name ?? "");
      setRequirementType(vendorProcurementRequirementDetails?.requirement_type ?? "");
      setRequirement(vendorProcurementRequirementDetails?.requirement ?? "");
      setAssignee(vendorProcurementRequirementDetails?.assignee ?? "");
      setStatus(vendorProcurementRequirementDetails?.status ?? "");
    }
  }, [vendorProcurementRequirementDetails]);

  const handleClose = () => {
    onClose();
    setName("");
    setAssignee("");
    setStatus(null);
    setRequirement(null);
    setRequirementType(null);
  };

  return (
    <SmallModal
      open={open}
      onClose={handleClose}
      title={`${vendorProcurementRequirementId ? "Edit" : "Add"} Vendor Procurement Requirement`}
      isLoading={vendorProcurementRequirementId ? isLoading : false}
    >
      <Stack gap="10px">
        <LinkExternalRequirementModal
          open={openLinkExternalRequirement}
          onClose={() => setOpenLinkExternalRequirement(false)}
          requirement={requirement}
          requirementType={requirementType}
          onLink={(requirement, requirementType) => {
            setRequirement(requirement);
            setRequirementType(requirementType);
            setOpenLinkExternalRequirement(false);
          }}
        />
        <Typography variant="h3" color={innerTextColor}>
          Name
        </Typography>
        <CustomInputValidation
          value={name}
          onChange={setName}
          required
          valueError={nameError}
          errorMessage="Name is required"
          field_type="String"
        />
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3" color={innerTextColor}>
            External Requirement
          </Typography>
          {!requirementType && (
            <TableTextWithEllipsis
              onClick={() => setOpenLinkExternalRequirement(true)}
              value="Link"
              fullWidth={false}
              hideTooltip
              color={theme.palette.custom.hyperlink}
            />
          )}
          {requirementType && requirement && (
            // <ExternalRequirementName requirementType={requirementType} requirement={requirement} />
            <TableTextWithEllipsis
              onClick={() => setOpenLinkExternalRequirement(true)}
              value={
                requirementType === "Policy"
                  ? `Policy: ${policy?.name}`
                  : requirementType === "Hyperlink"
                  ? requirement
                  : requirementType === "Jira Ticket"
                  ? `[${JSON.parse(requirement)?.issue_type}] ${JSON.parse(requirement)?.summary}`
                  : requirement
              }
              fullWidth={false}
              color={theme.palette.custom.hyperlink}
            />
          )}
        </Box>
        <Typography variant="h3" color={innerTextColor}>
          Owner
        </Typography>
        <CustomInputValidation
          value={assignee}
          onChange={setAssignee}
          required={false}
          valueError={false}
          errorMessage="Owner is required"
          field_type="User"
        />
        <Typography variant="h3" color={innerTextColor}>
          Status
        </Typography>
        <SearchableDropdown<VendorProcurementRequirementStatusOptions>
          value={status}
          onChange={(option) => {
            setStatus(option);
          }}
          getOptions={(search: string) =>
            statusOptions.filter((option) => option.toLowerCase().includes(search.toLowerCase()))
          }
          getOptionLabel={(option) => ""}
          isOptionEqualToValue={(a, b) => a === b}
          startAdornment={
            !!status ? (
              <Box display="flex" alignItems="center">
                <StatusBadge status={status as any} />
              </Box>
            ) : null
          }
          renderOption={(props, option) => {
            return <StatusBadge status={option as any} />;
          }}
        />
        <LoadingButton
          variant="contained"
          onClick={onCreateCallback}
          loading={loading}
          disabled={!name || !requirementType || !requirement || !assignee || !status}
        >
          {vendorProcurementRequirementId ? "Save" : "Add"}
        </LoadingButton>
      </Stack>
    </SmallModal>
  );
};
