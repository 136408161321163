import { useEffect, useState } from "react";
import { useFile } from "../../../hooks/useFiles";
import { LoadingButton } from "@mui/lab";
import { Stack, Box, Typography, IconButton, TextField, useTheme } from "@mui/material";
import Dropzone from "react-dropzone";
import { NavbarIcon } from "../../../atoms/navbar/Icon";
import CKEditorField from "../../../components/custom-fields/CKEditorField";
import { DropZoneIndicator } from "../../../components/FileAttachmentForm";
import { SmallModal } from "../../../components/SmallModal";
import { openSnackbar } from "../../../context/SnackbarContext";

type AuditorFileUploadModalProps = {
  open: boolean;
  onClose: () => void;
  onUpload: (data: { file: File; name: string; description: string }) => void;
  fileId: string;
};

export const MAX_FILE_SIZE_MB = 10;

export const AuditorFileUploadModal = (props: AuditorFileUploadModalProps) => {
  const { open, onClose, onUpload, fileId } = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const { data: files, isLoading } = useFile(fileId);

  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (files) {
      const auditorFile = new File([], files.filename, { type: files.mimetype });
      setFile(auditorFile);
      setName(files.name ?? files.filename);
      setDescription(files.description ?? "");
    }
  }, [files]);

  const handleClose = () => {
    onClose();
    setFile(null);
    setName("");
    setDescription("");
  };

  return (
    <SmallModal
      open={open}
      onClose={handleClose}
      size="small"
      sx={{
        ".MuiPaper-root": {
          padding: "0 !important",
        },
      }}
      isLoading={isLoading}
    >
      <Stack gap="10px">
        <Box
          display="flex"
          padding="10px 10px 0px 10px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3">{fileId ? "Edit File" : "Upload File"}</Typography>
          <IconButton aria-label="close" onClick={handleClose} sx={{ padding: 0 }}>
            <NavbarIcon variant="dialog-close" sx={{ width: "auto", height: "auto" }} />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          border={`1px solid ${theme.palette.custom.secondaryBorder}`}
          padding="10px"
          borderLeft="0"
          borderRight="0"
        >
          <Dropzone
            noClick
            multiple={false}
            onDrop={async (acceptedFiles, fileRejections) => {
              if (fileRejections.length) {
                const errorMessage = fileRejections[0].errors[0].message;
                if (/File is larger than/.test(errorMessage)) {
                  // error doesn't display the size in human readable format. Show custom message
                  openSnackbar(`File size limit is ${MAX_FILE_SIZE_MB}MB`, "error");
                } else {
                  openSnackbar(errorMessage, "error");
                }
              } else if (acceptedFiles.length) {
                const fileToUpload = acceptedFiles[0];
                setFile(fileToUpload);
                setName(fileToUpload.name);
              }
            }}
            maxSize={MAX_FILE_SIZE_MB * 1024 * 1024}
          >
            {({ getRootProps, getInputProps, isDragAccept, open: openFilePicker }) => (
              <Box {...getRootProps()} position="relative">
                <input {...getInputProps()} />
                {isDragAccept && <DropZoneIndicator />}
                {!file ? (
                  <Stack gap="10px">
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="10px"
                      border={`1px dashed ${theme.palette.custom.grayTypography}`}
                      borderRadius="3px"
                      padding="16px 10px"
                      sx={{
                        backgroundColor: theme.palette.custom.secondaryBackground,
                      }}
                    >
                      <Typography variant="body2" textAlign="center">
                        Select your file or drag and drop it, supported files .pdf, .docx up to
                        10MB.
                      </Typography>
                      <Box display="flex" justifyContent="center">
                        <LoadingButton
                          variant="contained"
                          onClick={openFilePicker}
                          sx={{
                            width: "154px",
                          }}
                        >
                          Upload File
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Stack>
                ) : (
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Box
                      display="flex"
                      gap="10px"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box display="flex" gap="10px" alignItems="center">
                        <NavbarIcon
                          variant="documentation"
                          sx={{
                            width: "16.36px",
                            height: "19.64px",
                          }}
                          color={theme.palette.custom.hyperlink}
                        />
                        <Typography variant="h4">{file.name}</Typography>
                      </Box>
                      {!fileId && (
                        <Box onClick={() => setFile(null)} sx={{ cursor: "pointer" }}>
                          <NavbarIcon
                            variant="trash-can"
                            color={theme.palette.custom.redTypography}
                            sx={{
                              width: "14px",
                              height: "16px",
                              minHeight: "16px",
                              minWidth: "14px",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                    <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
                      Name
                    </Typography>
                    <TextField value={name} onChange={(e) => setName(e.target.value)} required />
                    <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
                      Description
                    </Typography>
                    <CKEditorField value={description} onChange={setDescription} />
                  </Box>
                )}
              </Box>
            )}
          </Dropzone>
        </Box>
        <Box padding="0px 10px 10px 10px" width="100%">
          <LoadingButton
            variant="contained"
            fullWidth
            onClick={() =>
              onUpload({
                file: file!,
                name,
                description,
              })
            }
            disabled={!file}
          >
            {fileId ? "Save" : "Upload"}
          </LoadingButton>
        </Box>
      </Stack>
    </SmallModal>
  );
};
