import { Box, Typography } from "@mui/material";
import { CustomInputValidation } from "../CustomInputValidation";
import { forwardRef, useImperativeHandle, useState } from "react";
import { validateEmail, validatePhoneNumber, validateURL } from "../../utilities/UIHelper";

type VendorContactInformationProps = {
  value: string;
  onChange: (newVal: any) => void;
  required?: boolean;
};

export const VendorContactInformation = forwardRef((props: VendorContactInformationProps, ref) => {
  const { value, onChange, required } = props;

  const [validationErrors, setValidationErrors] = useState({
    url: false,
    email: false,
    phoneNumber: false,
  });

  const handleKeyChange = (newValue: string, key: string) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, [key]: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const getValue = (key: string) => {
    const valueJson = JSON.parse(value || "{}");
    return valueJson[key];
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const errors = {
        url: getValue("url") && !validateURL(getValue("url")),
        email: getValue("main_contact_email") && !validateEmail(getValue("main_contact_email")),
        phoneNumber:
          getValue("main_contact_phone") && !validatePhoneNumber(getValue("main_contact_phone")),
      };

      setValidationErrors(errors);

      return !Object.values(errors).some((hasError) => hasError);
    },
  }));

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" gap="10px">
      <Typography variant="h3">URL</Typography>
      <CustomInputValidation
        value={getValue("url")}
        onChange={(e) => handleKeyChange(e, "url")}
        required={false}
        valueError={validationErrors.url}
        errorMessage="URL is incorrect"
        field_type="String"
      />
      <Typography variant="h3">Address</Typography>
      <CustomInputValidation
        value={getValue("address")}
        onChange={(e) => handleKeyChange(e, "address")}
        required={false}
        valueError={false}
        errorMessage="Address is required"
        field_type="String"
      />
      <Typography variant="h3">Main Contact Name</Typography>
      <CustomInputValidation
        value={getValue("main_contact_name")}
        onChange={(e) => handleKeyChange(e, "main_contact_name")}
        required={false}
        valueError={false}
        errorMessage="Name is required"
        field_type="String"
      />
      <Typography variant="h3">Email</Typography>
      <CustomInputValidation
        value={getValue("main_contact_email")}
        onChange={(e) => handleKeyChange(e, "main_contact_email")}
        required={false}
        valueError={validationErrors.email}
        errorMessage="Email is incorrect"
        field_type="String"
      />
      <Typography variant="h3">Phone Number</Typography>
      <CustomInputValidation
        value={getValue("main_contact_phone")}
        onChange={(e) => handleKeyChange(e, "main_contact_phone")}
        required={false}
        valueError={validationErrors.phoneNumber}
        errorMessage="Phone Number is incorrect"
        field_type="String"
      />
    </Box>
  );
});
