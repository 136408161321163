import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { IconButton } from "../atoms/IconButton";
import { DisplayHyperparameterValue } from "../components/DisplayHyperparameterValue";
import { Table } from "../components/Table";
import { FilterOption, QueryFilter } from "../models/types";
import { isSameQueryFilter } from "../utilities/common";
import { isJSON } from "../utilities/UIHelper";
import { IColumnVisibility } from "./ColumnVisibility";
import { JSONView } from "./JSONView";
import { QueryFilterControl } from "./QueryFilterControl";
import { TableSearch } from "./TableSearch";
import { TableTextWithEllipsis } from "./TableTextWithEllipsis";
import { ShowMore } from "./ShowMore";

type JSONTableViewProps = {
  json: string;
  keyName: string;
  keyColumnHeader: string;
  valueName: string;
  valueColumnHeader: string;
  showDescription?: boolean;
  descriptionName?: string;
  editing?: boolean;
  setOpen?: () => void;
};

type HyperparameterObj = {
  id: string;
  name: string;
  value: any;
  description?: string;
};

export const JSONTableView = (props: JSONTableViewProps) => {
  const {
    json,
    keyName,
    valueName,
    keyColumnHeader,
    valueColumnHeader,
    showDescription = false,
    descriptionName,
    editing = false,
    setOpen = () => {},
  } = props;
  const theme = useTheme();

  const FILTER_OPTIONS: FilterOption<any>[] = [
    { label: keyColumnHeader, value: "name" },
    { label: valueColumnHeader, value: "value" },
  ];
  const columnVisibility: IColumnVisibility<HyperparameterObj>[] = [
    {
      field: "name",
      headerName: keyColumnHeader,
      visible: true,
      columnMinWidth: 300,
      columnMaxWidth: 500,
      renderer: (row) => <TableTextWithEllipsis value={row.name} />,
    },
    {
      field: "value",
      headerName: valueColumnHeader,
      visible: true,
      renderer: (row) => <DisplayHyperparameterValue value={row.value} />,
    },
    ...(showDescription
      ? ([
          {
            field: "description",
            headerName: "Description",
            visible: true,
          },
        ] as IColumnVisibility<HyperparameterObj>[])
      : []),
  ];
  const [queryFilters, setQueryFilters] = useState<QueryFilter<any>[]>([]);

  const [selectedView, setSelectedView] = useState<"table" | "json">("table");
  const hyperparameters =
    typeof json === "string"
      ? isJSON(json)
        ? (() => {
            const parsed = JSON.parse(json);
            return typeof parsed === "object" &&
              parsed !== null &&
              Object.entries(parsed).length === 0
              ? []
              : parsed;
          })()
        : []
      : typeof json === "object" && json !== null
      ? Object.keys(json).length === 0
        ? []
        : json
      : [];
  const rows = hyperparameters?.map((item: any, index: number) => {
    return {
      id: index.toString(),
      name: item[keyName],
      value: item[valueName],
      description: showDescription && descriptionName ? item[descriptionName] : "",
    };
  });
  const [data, setData] = useState<HyperparameterObj[]>(rows);

  const onAddFilter = (newQueryFilter: QueryFilter<any>) => {
    setQueryFilters((filters) => [
      newQueryFilter,
      ...filters.filter((qf) => !isSameQueryFilter(qf, newQueryFilter)),
    ]);
  };
  const onRemoveQueryFilter = (toRemove: QueryFilter<any>) => {
    setQueryFilters((queryFilters) =>
      queryFilters.filter((qf) => !isSameQueryFilter(toRemove, qf))
    );
  };

  useEffect(() => {
    if (queryFilters.length) {
      const queryParameters = queryFilters.map((queryFilter) => queryFilter.value);
      const filterParameters = rows.filter((row: any) => queryParameters.includes(row.name));
      setData([...filterParameters]);
    } else {
      setData([...rows]);
    }
  }, [queryFilters]);

  try {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        border={`1px solid ${theme.palette.custom.primaryBorder}`}
        borderRadius="6px"
      >
        <Box
          width="100%"
          padding="10px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <TableSearch filterOptions={FILTER_OPTIONS} onAddFilter={onAddFilter} rows={rows} />
          <Box display="flex" gap="10px" alignItems="center">
            <IconButton
              color={theme.palette.custom.hyperlink}
              height="16px"
              width="16px"
              onClick={() => navigator.clipboard.writeText(json)}
              variant="copy"
              padding="7px"
            />
            {editing && (
              <IconButton
                color={theme.palette.custom.hyperlink}
                height="16px"
                width="16px"
                onClick={setOpen}
                variant="edit-icon"
                padding="7px"
              />
            )}
            <Box display="flex">
              <Box
                display="flex"
                padding="10px 8px"
                alignItems="center"
                gap="10px"
                border={`1px solid ${
                  selectedView === "table"
                    ? theme.palette.custom.hyperlink
                    : theme.palette.custom.grey[500]
                }`}
                bgcolor={
                  selectedView === "table"
                    ? theme.palette.custom.secondaryBackground
                    : theme.palette.custom.primaryBackground
                }
                sx={{
                  ":hover": {
                    cursor: "pointer",
                  },
                  borderTopLeftRadius: "3px",
                  borderBottomLeftRadius: "3px",
                }}
                onClick={() => setSelectedView("table")}
              >
                <Typography
                  variant="h5"
                  color={
                    selectedView === "table"
                      ? theme.palette.custom.hyperlink
                      : theme.palette.custom.primaryTypography
                  }
                >
                  Table View
                </Typography>
              </Box>
              <Box
                display="flex"
                padding="10px 8px"
                alignItems="center"
                gap="10px"
                border={`1px solid ${
                  selectedView === "json"
                    ? theme.palette.custom.hyperlink
                    : theme.palette.custom.grey[500]
                }`}
                borderLeft="0px"
                bgcolor={
                  selectedView === "json"
                    ? theme.palette.custom.secondaryBackground
                    : theme.palette.custom.primaryBackground
                }
                sx={{
                  ":hover": {
                    cursor: "pointer",
                  },
                  borderTopRightRadius: "3px",
                  borderBottomRightRadius: "3px",
                }}
                onClick={() => setSelectedView("json")}
              >
                <Typography
                  variant="h5"
                  color={
                    selectedView === "json"
                      ? theme.palette.custom.hyperlink
                      : theme.palette.custom.primaryTypography
                  }
                >
                  JSON View
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {queryFilters && queryFilters.length > 0 && (
          <Box margin="0px 10px 10px 10px">
            <QueryFilterControl
              queryFilters={queryFilters}
              onRemoveQueryFilter={onRemoveQueryFilter}
            />
          </Box>
        )}
        {selectedView === "json" ? (
          <Box
            display="flex"
            padding="10px"
            borderTop={`1px solid ${theme.palette.custom.primaryBorder}`}
          >
            <JSONView value={json} />
          </Box>
        ) : (
          <Box width="100%">
            <Table
              columnsVisibility={columnVisibility}
              rows={data}
              smallEmptyHeight
              minEmptyHeight
              emptyTableComponent={
                <Typography
                  variant="body2"
                  color={theme.palette.custom.grayTypography}
                  paddingY="10px"
                  textAlign="start"
                  width="100%"
                >
                  No Parameters
                </Typography>
              }
            />
          </Box>
        )}
      </Box>
    );
  } catch (e) {
    return <ShowMore description={json} />;
  }
};
