import { ComplianceFramework, ComplianceFrameworkFetchOptions } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./ApiService";

const frameworkEndpoints = {
  frameworks: () => "/compliance_frameworks",
  framework: (frameworkId: string) => `/compliance_frameworks/${frameworkId}`,
};

export const getFrameworks = (params: ComplianceFrameworkFetchOptions) => {
  return httpGetAuthenticated<ListResponse<ComplianceFramework>>(frameworkEndpoints.frameworks(), {
    params,
  });
};

export const getFramework = (frameworkId: string, params?: { fairo_data?: boolean }) => {
  return httpGetAuthenticated<ComplianceFramework>(frameworkEndpoints.framework(frameworkId), {
    params,
  });
};

export const addFramework = async ({
  name,
  description,
  file,
}: {
  name: string;
  description: string;
  file: File;
}) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("description", description);
  formData.append("image", file);
  const { data } = await httpPostAuthenticated<ComplianceFramework>(
    frameworkEndpoints.frameworks(),
    formData
  );
  queryClient.invalidateQueries(QueryKey.ComplianceFramework);
  return data;
};

export const editFramework = async (
  frameworkId: string,
  {
    name,
    description,
    file,
  }: {
    name?: string;
    description?: string;
    file?: File | null;
  }
) => {
  const formData = new FormData();
  if (name) {
    formData.append("name", name);
  }
  if (description) {
    formData.append("description", description);
  }
  if (file || file === null) {
    formData.append("image", file ?? "");
  }
  const { data } = await httpPatchAuthenticated(
    frameworkEndpoints.framework(frameworkId),
    formData
  );
  queryClient.invalidateQueries(QueryKey.ComplianceFramework);
  return data;
};
