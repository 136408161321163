import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import { useQuestionnaireAnswers } from "../../hooks/useAnswers";
import { CustomAnswer, Question, Questionnaire } from "../../models/types";
import {
  addQuestionnaireAnswer,
  editQuestionnaireAnswer,
} from "../../services/QuestionnaireAnswerService";
import queryClient, { QueryKey } from "../../state/QueryStore";
import { QuestionDisplay } from "./QuestionDisplay";
import { QuestionNavigation } from "./QuestionNavigation";

type WizardQuestionDisplayProps = {
  question: Question;
  questionnaire: Questionnaire;
  questionIndex: number;
  answer: CustomAnswer;
  onChange: (value: string | null, models?: string) => void;
  relatedObjectId: string;
  handleNext: () => void;
  valueError: boolean;
  loading: boolean;
  handlePrevious: () => void;
  relatedObjectName: string;
  setLoading?: (value: boolean) => void;
};

export const WizardQuestionDisplay = ({
  question,
  questionnaire,
  questionIndex,
  answer,
  onChange,
  relatedObjectId,
  handleNext,
  valueError,
  loading,
  handlePrevious,
  relatedObjectName,
  setLoading,
}: WizardQuestionDisplayProps) => {
  const { data: oldAnswer } = useQuestionnaireAnswers({
    questionnaire: questionnaire.id,
    question: questionnaire.questions[questionIndex].ref,
    ...(relatedObjectId !== "" && { "related_object_id[]": [relatedObjectId] }),
  });
  const questionFieldRef = useRef();
  const handleSaveDraft = async (models?: string[]) => {
    setLoading && setLoading(true);
    try {
      // Save draft of the question
      if (questionFieldRef.current) {
        const isValid = (questionFieldRef?.current as any)?.validate();
        if (!isValid) {
          return;
        }
      }

      if (oldAnswer && oldAnswer.results.length > 0) {
        await editQuestionnaireAnswer(oldAnswer.results[0].id, {
          answer: answer.answer,
          status: "PENDING REVIEW",
        });
      } else {
        await addQuestionnaireAnswer({
          ...answer,
          status: "PENDING REVIEW",
        });
      }
      queryClient.invalidateQueries(QueryKey.UseCase);
      handleNext();
    } catch (e) {
      setLoading && setLoading(false);
    }
  };

  useEffect(() => {
    if (oldAnswer && oldAnswer.results.length > 0) {
      onChange(oldAnswer.results[0].answer);
    }
  }, [oldAnswer]);

  return (
    <Box display="flex" flexGrow={1} paddingTop="50px" flexDirection="column" gap="16px">
      <QuestionDisplay
        ref={questionFieldRef}
        hideQuestionNumber
        hideQuestionnaireName
        question={question}
        questionnaire={questionnaire}
        questionIndex={questionIndex}
        value={answer.answer}
        onChange={onChange}
        valueError={valueError}
        relatedObjectId={relatedObjectId}
        handleNext={() => handleSaveDraft()}
        relatedObjectName={relatedObjectName}
      />
      <QuestionNavigation
        isQuestionnaireFinalized={false}
        impactAssessmentId={""}
        loading={loading}
        questionnaireId={""}
        currentQuestionIndex={questionIndex}
        handleNext={() => handleSaveDraft()}
        handlePrevious={handlePrevious}
        isSingleQuestion={false}
        question={question}
        questionsCount={questionnaire.questions.length}
        showAssignUser={false}
      />
    </Box>
  );
};
