import { Box, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { useFile } from "../../hooks/useFiles";
import { FileAttachment } from "../../models/types";
import { NavbarIcon } from "../../atoms/navbar/Icon";
import { useState } from "react";
import { ReactComponent as ThreeDotsLightIcon } from "../../assets/general/three-dots-light.svg";
import { ReactComponent as ThreeDotsDarkIcon } from "../../assets/general/three-dots-dark.svg";
import { defaultDateDisplay } from "../../utilities/UIHelper";
import UserTag from "../../components/UserTag";

type AuditAuditorFileProps = {
  file: FileAttachment;
  onEdit: (fileId: string) => void;
  onSeeDetails: (fileId: string) => void;
  onDelete: (fileId: string, fileName: string) => void;
  onView: (file: FileAttachment) => void;
  showEditAndDelete?: boolean;
};

export const AuditAuditorFile = (props: AuditAuditorFileProps) => {
  const { file, onEdit, onSeeDetails, onDelete, onView, showEditAndDelete = true } = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMenuVisible(!isMenuVisible);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    setMenuVisible(!isMenuVisible);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      padding="10px"
      border={`1px solid ${theme.palette.custom.secondaryBorder}`}
      borderRadius="3px"
      // flexGrow="0 0"
      flexBasis="calc(25% - 8px)"
      onClick={() => !isMenuVisible && onView(file)}
      sx={{
        cursor: "pointer",
      }}
    >
      <Box display="flex" gap="8px" justifyContent="space-between" alignItems="center">
        <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
          {file.name}
        </Typography>
        <Box
          width="16px"
          height="100%"
          alignItems="center"
          alignContent="center"
          onClick={toggleMenu}
        >
          <Box
            sx={{
              display: "flex",
              cursor: "pointer",
              transform: "rotate(90deg)",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isDarkMode ? <ThreeDotsDarkIcon /> : <ThreeDotsLightIcon />}
          </Box>
        </Box>
        <Menu
          elevation={0}
          id="three-dots-menu"
          MenuListProps={{
            "aria-labelledby": "three-dots-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            gap: "5px",
            "& .MuiPaper-root": {
              "& .MuiMenu-list": {
                padding: "4px",
                width: "146px",
              },
              "& .MuiMenuItem-root": {
                padding: "4px",
                "&:hover": {
                  backgroundColor: theme.palette.custom.secondaryBackground,
                },
              },
            },
          }}
        >
          {showEditAndDelete && (
            <MenuItem>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
                gap="5px"
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit(file.id);
                  handleClose(event);
                }}
              >
                <Typography variant="body2">Edit</Typography>
                <NavbarIcon
                  variant="edit-icon"
                  color={
                    !isDarkMode
                      ? theme.palette.custom.blueTypography
                      : theme.palette.custom.whiteTypography
                  }
                  sx={{ height: "16px", width: "16px" }}
                />
              </Box>
            </MenuItem>
          )}
          <MenuItem>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
              gap="5px"
              onClick={(event) => {
                event.stopPropagation();
                onSeeDetails(file.id);
                handleClose(event);
              }}
            >
              <Typography variant="body2">See Details</Typography>
              <NavbarIcon
                variant="show"
                color={
                  !isDarkMode
                    ? theme.palette.custom.blueTypography
                    : theme.palette.custom.whiteTypography
                }
                sx={{ height: "18px", width: "18px" }}
              />
            </Box>
          </MenuItem>
          {showEditAndDelete && (
            <MenuItem>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
                gap="5px"
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(file.id, file.name ?? file.filename);
                  handleClose(event);
                }}
              >
                <Typography variant="body2">Delete</Typography>
                <NavbarIcon
                  variant="trash-can"
                  color={theme.palette.custom.redTypography}
                  sx={{ height: "18px", width: "18px" }}
                />
              </Box>
            </MenuItem>
          )}
        </Menu>
      </Box>
      <Box display="flex" gap="8px" justifyContent="space-between" alignItems="center">
        <Typography variant="body2">Uploaded On:</Typography>
        <Typography variant="body2">{defaultDateDisplay(file.created_on ?? "")}</Typography>
      </Box>
      <Box display="flex" gap="8px" justifyContent="space-between" alignItems="center">
        <Typography variant="body2">By:</Typography>
        <UserTag showNameInitials name={file.created_by?.name ?? ""} />
      </Box>
    </Box>
  );
};
