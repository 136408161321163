import { Stack, Typography } from "@mui/material";
import { SmallModal } from "../../components/SmallModal";
import { LoadingButton } from "@mui/lab";
import CKEditorField from "../../components/custom-fields/CKEditorField";
import { CustomInputValidation } from "../CustomInputValidation";

type ConnectModelsUseCaseModalProps = {
  open: boolean;
  onClose: () => void;
  name: string;
  setName: (value: string) => void;
  nameError: boolean;
  description: string | null;
  setDescription: (value: string | null) => void;
  onCreate: () => void;
};

export const ConnectModelsUseCaseModal = (props: ConnectModelsUseCaseModalProps) => {
  const { open, onClose, name, setName, nameError, description, setDescription, onCreate } = props;

  return (
    <>
      <SmallModal open={open} onClose={onClose} title="Create Fairo Model">
        <Stack gap="10px">
          <Typography variant="h3">Name</Typography>
          <CustomInputValidation
            value={name}
            onChange={setName}
            valueError={nameError}
            errorMessage="Name is required"
            field_type="String"
          />
          <Typography variant="h3">Description</Typography>
          <CKEditorField value={description} onChange={setDescription} />
          <LoadingButton variant="contained" onClick={onCreate} disabled={!name}>
            Create
          </LoadingButton>
        </Stack>
      </SmallModal>
    </>
  );
};
