import { Box, useTheme } from "@mui/material";
import { NavbarIcon } from "../../atoms/navbar/Icon";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { CreatePolicyOption } from "../../components/CreatePolicyOption";
import { useState } from "react";
import { FileAttachment } from "../../models/types";
import { AuditorFileDetailsModal } from "../modals/audits/AuditorFileDetailsModal";
import { AuditorFileUploadModal } from "../modals/audits/AuditorFileUploadModal";
import { addFile, deleteAttachment } from "../../services/FileService";
import { QueryKey } from "../../state/QueryStore";
import { openSnackbar } from "../../context/SnackbarContext";
import { CardTable } from "../CardTable";
import { AuditAuditorFile } from "../../molecules/audits/AuditAuditorFile";
import { useFilesByPage } from "../../hooks/useFiles";

type FileAttachmentListProps = {
  value: any;
  onChange: (newVal: any) => void;
  relatedObjectId: string;
};

export const FileAttachmentList = (props: FileAttachmentListProps) => {
  const { value, onChange, relatedObjectId } = props;
  const theme = useTheme();

  const fileIds = JSON.parse(value);
  const [openUploadFileModal, setOpenUploadFileModal] = useState(false);
  const [fileId, setFileId] = useState("");
  const [openSeeFileDetailsModal, setOpenSeeFileDetailsModal] = useState(false);
  const [openDeleteFileModal, setOpenDeleteFileModal] = useState(false);
  const [deleteFileName, setDeleteFileName] = useState("");

  const onUploadFile = async (data: { file: File; name: string; description?: string }) => {
    try {
      const file = await addFile(
        relatedObjectId,
        data.file,
        QueryKey.Vendor,
        data.name,
        data.description
      );
      onChange(JSON.stringify([...fileIds, file.data.id]));
    } catch {
      openSnackbar("Failed to upload file", "error");
    } finally {
      setOpenUploadFileModal(false);
    }
  };

  const onRemoveFile = async () => {
    try {
      await deleteAttachment(fileId, QueryKey.Vendor);
      onChange(JSON.stringify(fileIds.filter((item: string) => item !== fileId)));
    } catch {
      openSnackbar("Failed to delete file", "error");
    } finally {
      setOpenDeleteFileModal(false);
      setDeleteFileName("");
      setFileId("");
    }
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" gap="10px">
      <AuditorFileDetailsModal
        open={openSeeFileDetailsModal}
        onClose={() => {
          setOpenSeeFileDetailsModal(false);
          setFileId("");
        }}
        fileId={fileId}
        onView={(file: FileAttachment) => {
          window.open(file.file_object, "_blank");
        }}
      />
      <AuditorFileUploadModal
        open={openUploadFileModal}
        onClose={() => {
          setOpenUploadFileModal(false);
          setFileId("");
        }}
        onUpload={onUploadFile}
        fileId={fileId}
      />
      <ConfirmationModal
        open={openDeleteFileModal}
        description={`Are you sure you want to remove Document ${deleteFileName}? Once it's removed it's gone for good.`}
        title={"Remove Document"}
        descriptionVariant="body2"
        onClose={() => {
          setOpenDeleteFileModal(false);
          setDeleteFileName("");
          setFileId("");
        }}
        onAccept={onRemoveFile}
        acceptText="Yes, remove document"
      />
      <CreatePolicyOption
        name="Upload a file"
        description="Supported files: .pdf & .docx up to 10MB."
        fullHeight={false}
        icon={
          <NavbarIcon
            variant="upload"
            sx={{
              width: "30px",
              height: "30px",
            }}
            color={theme.palette.custom.hyperlink}
          />
        }
        onClick={() => setOpenUploadFileModal(true)}
      />
      {fileIds.length > 0 && (
        <CardTable
          renderItem={(file) => (
            <AuditAuditorFile
              file={file}
              onEdit={(fileId: string) => {
                setFileId(fileId);
                setOpenUploadFileModal(true);
              }}
              onSeeDetails={(fileId: string) => {
                setFileId(fileId);
                setOpenSeeFileDetailsModal(true);
              }}
              onDelete={(fileId: string, fileName: string) => {
                setDeleteFileName(fileName);
                setFileId(fileId);
                setOpenDeleteFileModal(true);
              }}
              onView={(file: FileAttachment) => {
                window.open(file.file_object, "_blank");
              }}
              showEditAndDelete={true}
            />
          )}
          hasPaddingTop={false}
          hasPaddingBottom={false}
          hasBorderRadiusTop={false}
          params={{ id: fileIds }}
          useGetData={useFilesByPage}
          rowsPerPage={4}
          padding={"0px"}
          height="100%"
          showBorder={false}
          rowsMenu={[4, 8, 12]}
        />
      )}
    </Box>
  );
};
