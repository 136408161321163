import { useState } from "react";
import {
  APIGetListHook,
  BaseFilters,
  FilterOption,
  RegistryFieldType,
  VendorProcurementRequirement,
} from "../../models/types";
import { Box, Button } from "@mui/material";
import { CreateVendorProcurementRequirementModal } from "../modals/vendors/CreateVendorProcurementRequirementModal";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { APITable } from "../APITable";
import { StatusBadge } from "../../atoms/StatusBadge";
import UserTag from "../../components/UserTag";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { defaultDateDisplay } from "../../utilities/UIHelper";
import { VendorProcurementRequirementsThreeDotsMenu } from "../vendors/VendorProcurementRequirementsThreeDotsMenu";
import { navigateToObject } from "../../utilities/GenericNavigateHelper";
import { useNavigate } from "react-router-dom";
import { EmptyTable } from "../../molecules/EmptyTable";

type VendorProcurementRequirementTableProps = {
  value: any;
  onChange: (newVal: any) => void;
  relatedObjectId: string;
};

export const VendorProcurementRequirementTable = (
  props: VendorProcurementRequirementTableProps
) => {
  const { value, onChange, relatedObjectId } = props;
  const navigate = useNavigate();

  const vendorProcurementRequirements = JSON.parse(value);
  const [open, setOpen] = useState(false);
  const [selectedRequirement, setSelectedRequirement] = useState<any | null>(null);
  const [deleteVendorProcurementRequirement, setDeleteVendorProcurementRequirement] = useState<
    any | null
  >(null);

  const onCreate = async (
    vendor: string,
    name: string,
    requirement_type: string,
    requirement: string,
    assignee: string,
    status: string,
    assignee_name?: string
  ) => {
    const newVal = [
      ...vendorProcurementRequirements,
      {
        id: vendorProcurementRequirements.length.toString(),
        vendor,
        name,
        requirement_type,
        requirement,
        assignee,
        status,
        assignee_name,
        updated_on: new Date().toISOString(),
      },
    ];
    onChange(JSON.stringify(newVal));
  };

  const onEdit = async (
    id: string,
    name?: string,
    requirement_type?: string,
    requirement?: string,
    assignee?: string,
    status?: string,
    assignee_name?: string
  ) => {
    const updatedRequirements = vendorProcurementRequirements.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          name,
          requirement_type,
          requirement,
          assignee,
          status,
          assignee_name,
          updated_on: new Date().toISOString(),
        };
      }
      return item;
    });
    onChange(JSON.stringify(updatedRequirements));
  };

  const onDelete = async () => {
    if (deleteVendorProcurementRequirement) {
      const updatedRequirements = vendorProcurementRequirements.filter(
        (item: any) => item.id !== deleteVendorProcurementRequirement.id
      );
      onChange(JSON.stringify(updatedRequirements));
      setDeleteVendorProcurementRequirement(null);
    }
  };

  const useVendorProcurementRequirement: APIGetListHook<any, any> = (params) => {
    const { page = 1, page_size: _pageSize = 100 } = params;
    const pageData = JSON.parse(value).slice((page - 1) * _pageSize, page * _pageSize);
    return {
      data: {
        count: JSON.parse(value).length ?? 0,
        next: null,
        previous: null,
        results: pageData,
      },
      isLoading: false,
    };
  };

  const columnsVisibility: IColumnVisibility<VendorProcurementRequirement>[] = [
    {
      field: "name",
      headerName: "Name",
      visible: true,
      columnMinWidth: 280,
      renderer: (requirement) => <TableTextWithEllipsis value={requirement.name} />,
    },
    {
      field: "assignee",
      headerName: "Owner",
      visible: true,
      columnMinWidth: 150,
      columnMaxWidth: 150,
      renderer: (requirement) => <UserTag showNameInitials userId={requirement.assignee} />,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
      columnMinWidth: 150,
      columnMaxWidth: 150,
      renderer: (requirement) => defaultDateDisplay(requirement.updated_on),
    },
    {
      field: "status",
      headerName: "Status",
      visible: true,
      columnMinWidth: 150,
      columnMaxWidth: 150,
      renderer: (requirement) => <StatusBadge status={requirement.status as any} />,
    },
    {
      field: "updated_by",
      headerName: "",
      visible: true,
      columnMinWidth: 50,
      columnMaxWidth: 50,
      renderer: (requirement) => {
        return (
          <VendorProcurementRequirementsThreeDotsMenu
            onEdit={() => {
              setSelectedRequirement(requirement);
              setOpen(true);
            }}
            onDelete={() => setDeleteVendorProcurementRequirement(requirement)}
          />
        );
      },
    },
  ];

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" gap="10px">
      <CreateVendorProcurementRequirementModal
        vendorId={relatedObjectId}
        open={open}
        onClose={() => {
          setOpen(false);
          setSelectedRequirement(null);
        }}
        onCreate={onCreate}
        onEdit={onEdit}
        vendorProcurementRequirementId={selectedRequirement?.id}
        vendorProcurementRequirementDetails={selectedRequirement}
      />
      <ConfirmationModal
        open={!!deleteVendorProcurementRequirement}
        onClose={() => setDeleteVendorProcurementRequirement(null)}
        title="Delete Vendor Procurement Requirement"
        description="Are you sure you want to delete this requirement?"
        descriptionVariant="body2"
        onAccept={onDelete}
        acceptText="Yes, Delete"
      />
      <APITable
        useGetData={useVendorProcurementRequirement}
        columnsVisibility={columnsVisibility}
        queryParams={{}}
        smallEmptyHeight
        rowOnClick={(requirement) => {
          navigateToObject(
            navigate,
            requirement.requirement_type === "Policy"
              ? "Asset"
              : (requirement.requirement_type as RegistryFieldType),
            requirement.requirement,
            () => {},
            () => {}
          );
        }}
        emptyTableComponent={
          <EmptyTable
            variant="vendor"
            label="Requirements"
            description="Add your first procurement requirement"
            actionLabel="Add"
            action={() => setOpen(true)}
          />
        }
        secondComponent={
          <Box display="flex" flexGrow={1} gap="10px" alignItems="center" justifyContent="flex-end">
            <Button variant="contained" onClick={() => setOpen(true)}>
              Add
            </Button>
          </Box>
        }
        showPagination={false}
        hideColumnVisibility
      />
    </Box>
  );
};
