import { useQuery } from "react-query";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { fetchFrameworks } from "../hooks/useFramework";
import { getFramework } from "../services/FrameworkService";
import { getFinalNumberFromPath, getLastPathByRegex } from "../utilities/UIHelper";

const FRAMEWORK_NAVIGATOR_KEY = "framework-navigator";
export const useFrameworkPathState = (locationState: any, path: string) => {
  const frameworkId = locationState?.frameworkId;
  const edit = locationState?.edit;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    frameworkId: string;
    edit: boolean;
  }>(
    [FRAMEWORK_NAVIGATOR_KEY, path],
    async () => {
      if (frameworkId != null) {
        return { frameworkId, edit };
      }

      const list = await fetchFrameworks({ framework_num: [number], fairo_data: true });
      const obj = list.results.find((o) => o.framework_num === Number(number));
      if (!obj) {
        navigate("/oversight/frameworks");
        return { frameworkId: null, edit };
      }

      return { frameworkId: obj.id, edit };
    },
    { suspense: true }
  );
};

const navigateToFramework = async (params: {
  navigate: NavigateFunction;
  frameworkId: string;
  frameworkNumber?: number;
  edit?: boolean;
  returnLink?: boolean;
}) => {
  const { frameworkId, frameworkNumber, edit, navigate, returnLink = false } = params;
  if (returnLink) {
    return `/oversight/frameworks/framework-${frameworkNumber}`;
  }
  let frameworkNum = frameworkNumber;
  if (!frameworkNum) {
    const framework = await getFramework(frameworkId);
    frameworkNum = framework.data.framework_num;
  }
  navigate(`/oversight/frameworks/framework-${frameworkNum}`, {
    state: {
      frameworkId,
      edit,
    },
  });
};

export { navigateToFramework };
