import { Box } from "@mui/material";
import { MultipleSelectionField } from "./MultipleSelection";

type OrganizationIntegrationsProps = {
  value: string;
  onChange: (newVal: any) => void;
  required?: boolean;
  metadata?: any;
};

export const OrganizationIntegrations = (props: OrganizationIntegrationsProps) => {
  const { value, onChange, required, metadata } = props;

  const integrations: {
    label: string;
    value: string;
  }[] = metadata?.integrations.map((integration: string) => {
    return {
      label: integration,
      value: integration.toLowerCase().replace(/ /g, "-"),
    };
  }) || [
    {
      label: "AWS",
      value: "aws",
    },
    {
      label: "Open AI",
      value: "open-ai",
    },
    {
      label: "MLFlow",
      value: "mlflow",
    },
    {
      label: "Databricks",
      value: "databricks",
    },
    {
      label: "Hugging Face",
      value: "hugging-face",
    },
    {
      label: "Github",
      value: "github",
    },
    {
      label: "Jira",
      value: "jira",
    },
  ];

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" gap="10px">
      <MultipleSelectionField
        value={value}
        onChange={onChange}
        required={required}
        choices={integrations}
        showIcon={true}
      />
    </Box>
  );
};
