import { Box, Typography, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { QuestionChoice } from "../../models/types";
import { SingleSelectionField } from "./SingleSelection";

type TermsAndConditionsProps = {
  value: string;
  onChange: (newVal: any) => void;
  required?: boolean;
  choices: QuestionChoice[];
};

export const TermsAndConditions = forwardRef((props: TermsAndConditionsProps, ref) => {
  const { value, onChange, required, choices } = props;
  const theme = useTheme();

  const [validationErrors, setValidationErrors] = useState({
    termsAndConditions: false,
  });

  useImperativeHandle(ref, () => ({
    validate: () => {
      const errors = {
        termsAndConditions: value.toLowerCase() !== "yes",
      };

      setValidationErrors(errors);

      // Return true if all fields are valid (no errors)
      return !Object.values(errors).some((hasError) => hasError);
    },
  }));

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" gap="10px">
      <SingleSelectionField
        value={value}
        onChange={onChange}
        required={required}
        choices={choices}
      />
      {validationErrors.termsAndConditions && (
        <Typography variant="small" color={theme.palette.custom.redTypography} marginLeft="14px">
          You need to accept the terms to continue
        </Typography>
      )}
    </Box>
  );
});
