import { NavbarIcon } from "../atoms/navbar/Icon";
import { ComplianceFramework } from "../models/types";

export const getFrameworkImage = (
  framework: ComplianceFramework,
  size: "small" | "large" = "large"
) => {
  try {
    if (framework.image) {
      return (
        <img
          src={framework.image}
          alt={framework.name}
          style={{ width: size === "small" ? "14px" : "50px" }}
        />
      );
    }
    return getFrameworkIcon(framework.name, size);
  } catch (e) {
    return <></>;
  }
};

export const getFrameworkIcon = (
  frameworkName: string,
  size: "small" | "large" = "large"
): React.ReactNode => {
  const sizes = {
    small: "14px",
    large: "50px",
  };
  if (frameworkName.toLowerCase().includes("fairo")) {
    return (
      <NavbarIcon
        variant="fairo-logo"
        color="none"
        sx={{
          width: sizes[size],
          height: sizes[size],
          minHeight: sizes[size],
          minWidth: sizes[size],
          padding: size === "small" ? "0px" : "5px",
        }}
      />
    );
  }
  if (frameworkName.toLowerCase().includes("nist")) {
    return (
      <NavbarIcon
        variant="nist-logo"
        color="none"
        sx={{
          width: sizes[size],
          height: sizes[size],
          minHeight: sizes[size],
          minWidth: sizes[size],
        }}
      />
    );
  }
  if (frameworkName.toLowerCase().includes("iso")) {
    return (
      <NavbarIcon
        variant="iso-logo"
        color="none"
        sx={{
          width: sizes[size],
          height: sizes[size],
          minHeight: sizes[size],
          minWidth: sizes[size],
        }}
      />
    );
  }
  if (frameworkName.toLowerCase().includes("eu ai")) {
    return (
      <NavbarIcon
        variant="euai-logo"
        color="none"
        sx={{
          width: sizes[size],
          height: sizes[size],
          minHeight: sizes[size],
          minWidth: sizes[size],
        }}
      />
    );
  }
  if (frameworkName.toLowerCase().includes("hitrust")) {
    return (
      <NavbarIcon
        variant="hitrust-logo"
        color="none"
        sx={{
          width: sizes[size],
          height: sizes[size],
          minHeight: sizes[size],
          minWidth: sizes[size],
        }}
      />
    );
  }
  return (
    <NavbarIcon
      variant="fairo-logo"
      color="none"
      sx={{
        width: sizes[size],
        height: sizes[size],
        minHeight: sizes[size],
        minWidth: sizes[size],
      }}
    />
  );
};
