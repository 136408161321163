import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useFile } from "../../../hooks/useFiles";
import { SmallModal } from "../../../components/SmallModal";
import { defaultDateDisplay } from "../../../utilities/UIHelper";
import UserTag from "../../../components/UserTag";
import { ShowMore } from "../../../molecules/ShowMore";
import { FileAttachment } from "../../../models/types";

type AuditorFileDetailsModalProps = {
  open: boolean;
  onClose: () => void;
  fileId: string;
  onView: (file: FileAttachment) => void;
};

export const AuditorFileDetailsModal = (props: AuditorFileDetailsModalProps) => {
  const { open, onClose, fileId, onView } = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const innerTextColor = theme.palette.custom.secondaryTypography;

  const { data: file, isLoading } = useFile(fileId);

  return (
    <SmallModal open={open} onClose={onClose} title="Auditor File">
      <Stack gap="10px">
        <Box display="flex" gap="10px" alignItems="center">
          <Typography variant="h3" color={innerTextColor} width="104px">
            File
          </Typography>
          <Typography
            variant="button"
            sx={{
              textDecoration: "underline",
              color: theme.palette.custom.hyperlink,
              cursor: "pointer",
            }}
            onClick={() => onView(file!)}
          >
            {file?.filename}
          </Typography>
        </Box>
        <Box display="flex" gap="10px" alignItems="center">
          <Typography variant="h3" color={innerTextColor} width="104px">
            Name
          </Typography>
          <Typography variant="body2">{file?.name}</Typography>
        </Box>
        {file?.description && (
          <>
            <Typography variant="h3" color={innerTextColor} width="126px">
              Description
            </Typography>
            <ShowMore description={file?.description} maxChar={250} richText={true} />
          </>
        )}
        <Box display="flex" gap="10px" alignItems="center">
          <Typography variant="h3" color={innerTextColor} width="104px">
            Uploaded On:
          </Typography>
          <Typography variant="body2">{defaultDateDisplay(file?.created_on ?? "")}</Typography>
        </Box>
        <Box display="flex" gap="10px" alignItems="center">
          <Typography variant="h3" color={innerTextColor} width="104px">
            By:
          </Typography>
          <UserTag name={file?.created_by?.name ?? ""} />
        </Box>
      </Stack>
    </SmallModal>
  );
};
